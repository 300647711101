﻿import {Injectable} from '@angular/core';
@Injectable()
export class GlobalVariableServices {
    globalVar: string;
    studyId: number;
    studySiteId: number;
    testName: string;
    orderNumber: string;

    setStudyId(studyId: any) {
        this.studyId = studyId;
    }
    getStudyId() {
        return this.studyId;
    }
    setStudySiteId(studySiteId: any) {
        this.studySiteId = studySiteId;
    }
    getStudySiteId() {
        return this.studySiteId;
    }
    getOrderNo(){
        return this.orderNumber;
    }
    setOrderNo(orderNumber:any){
        this.orderNumber = orderNumber;
    }
}
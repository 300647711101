﻿import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '../services/session.service';

@Directive({
    selector: '[hasReadPermission]'
})

export class ReadPermissionDirective {
    @Input('hasReadPermission') featureId: string;
    userPermissions: any;
    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
        ,private sessionService:SessionService) {
        this.userPermissions = JSON.parse(this.sessionService.getItem('userPermissions'));
    }
    ngOnInit() {
        this.setReadPermissions();
    }

    setReadPermissions() {
        var features = this.userPermissions.filter(x => x.FeatureId.toUpperCase() === this.featureId);
        if(features.length > 0){
        var readPermission = features[0].ReadAccess;
        if (readPermission) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
        }else
        {
            this.viewContainer.clear();
        }
    }
}

import {ErrorHandler,Injectable,Injector} from '@angular/core';
@Injectable()
export class GlobalExceptionHandler extends ErrorHandler {
    constructor() {
        super();
    }
    handleError(error: any): void{
        console.log(error);
       
    }
}
import {Action} from '@ngrx/store';
import {CartItem} from '../ProductDetails/Model/productdetails.model';


export const Create_Store = 'Create_Store'
export const Add_Product = 'Add_Product';
export const Load = 'Load';
export const Load_Success = 'Load_Success';
export const Remove_Product = 'Remove_Product';
export const Add_Product_Success = 'Add_Product_Success';
export const Remove_Product_Success = 'Remove_Product_Success';
export const Decrement_Product_Quantity = 'Decrement_Product_Quantity';
export const Decrement_Product_Quantity_Success = 'Decrement_Product_Quantity_Success';
export const Remove_DB_items = 'Remove_DB_items';
export const clear_State = 'clear_State';

//Add Product To Cart
export class AddProductAction implements Action {
    readonly type = Add_Product;

    constructor(public payload: CartItem) {

    }

}

//Load Products from Indexed db
export class LoadAction implements Action {
    readonly type = Load;
    constructor(public payload: string) {

    }
}

//Remove Preoduct from cart
export class RemoveProductAction implements Action {
    readonly type = Remove_Product;

    constructor(public payload: CartItem) {

    }

}

//Add Product to Store
export class AddProductSuccessAction implements Action {
    readonly type = Add_Product_Success;
    constructor(public payload: CartItem) {

    }

}

//Remove Product from store
export class RemoveProductSuccessAction implements Action {
    readonly type = Remove_Product_Success;
    constructor(public payload: CartItem) {}

}




//Load Product to store
export class LoadSuccessAction implements Action {

    readonly type = Load_Success;
    constructor(public payload: CartItem[]) {

    }
}

//Remove cart items on logout
export class RemoveDbItems implements Action {
    readonly type = Remove_DB_items;

    constructor(public payload: string) {

    }
}

//Clear the store on logout
export class ClearState implements Action {
    readonly type = clear_State;

    constructor() {

    }
}
export type Actions=
    AddProductAction |
    RemoveProductAction |
    AddProductSuccessAction |
    RemoveProductSuccessAction |
    LoadAction |
    LoadSuccessAction |
    RemoveDbItems |
    ClearState
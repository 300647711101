import { NgModule }              from '@angular/core';
import { SharedModule }          from '../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent }         from './login.component';
import { routing }               from './login.route';
import { ReCaptchaComponent } from 'angular2-recaptcha/lib/captcha.component';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { HttpClient } from '../../shared/HttpClient';
import {LoginService} from './Services/login.service';
import { DialogModule } from 'primeng/primeng';
import { ConfigService } from '../../config/ConfigService';
import{BlockUIService} from"../../shared/services/blockui.service";
import { VersionCheckService } from '../../HashPolling/version-check.service';

// Routed Feature module
@NgModule({
  imports:      [CommonModule,FormsModule, SharedModule,DialogModule, routing ,ReCaptchaModule],
  declarations: [ LoginComponent ],
  providers: [LoginService, HttpClient,ConfigService,BlockUIService,VersionCheckService]
})

export class LoginModule { }

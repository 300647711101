import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes }        from '@angular/router';

import { LoginComponent } from './login.component';



const routes: Routes = [
 {
    	path: '',
    	component: LoginComponent
    },
  { path: 'login', component: LoginComponent }
];


export const routing: ModuleWithProviders = RouterModule.forChild(routes);

import { Directive, Input, HostListener } from '@angular/core';
import { Table } from 'primeng/table';

@Directive({
  selector: '[pAddRow]'
})
export class AddRowDirective {
  @Input() table1: Table;
  @Input() newRow: any;

  @HostListener('click', ['$event'])
  onClick(event: Event) {

    // Insert a new row
    this.table1.value.push(this.newRow);

    // Set the new row in edit mode
 //  this.table1.onEditInit(this.newRow);

    event.preventDefault();
  }
}

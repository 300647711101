'use strict';
export var tokenKey: string = 'Not-Set';

export var authUser = {
    isLoggedIn: false,
    userName: ''
};

export function setTokenKey(value: string) {
    tokenKey = value;
}

export class UserRoles {
    public static All:string='All';
    public static Internal:string='Internal';
    public static IS: string = '39D7075B-70D6-E611-9F36-005056B00B08';
    public static Administrator: string = '4A2A10A2-7CD3-E611-9F36-005056B00B08';
    public static SponsorContact: string = '3AD7075B-70D6-E611-9F36-005056B00B08';
    public static MedicalMonitor: string = '3BD7075B-70D6-E611-9F36-005056B00B08';
    public static Monitor: string = '3CD7075B-70D6-E611-9F36-005056B00B08';
    public static PrincipalInvestigator: string = '564EE965-70D6-E611-9F36-005056B00B08';
    public static CoInvestigator: string = '3DD7075B-70D6-E611-9F36-005056B00B08';
    public static StudyNurse: string = '3FD7075B-70D6-E611-9F36-005056B00B08';
    public static ReportOnly: string = '40D7075B-70D6-E611-9F36-005056B00B08';
    public static Microbiologist: string = '3ED7075B-70D6-E611-9F36-005056B00B08';
    public static ISReadOnly: string = '94B4BBDF-3FE8-E611-9F36-005056B00B08';
    public static PM: string = '53B5AEFE-3FE8-E611-9F36-005056B00B08';
    public static Others: string = 'F7F0C30E-40E8-E611-9F36-005056B00B08';
    public static PSM: string = '43D34922-252F-E711-B57D-005056B00B08';
    public static GLS: string = 'E4A0EC25-C1AC-E711-A836-005056B00B08';
    public static Reviewer: string = '4DDB3199-6948-E811-8126-005056B00B08';
    public static BD: string = '4EDB3199-6948-E811-8126-005056B00B08';
    public static DM: string = '4FDB3199-6948-E811-8126-005056B00B08';
    public static CRO: string = 'EBB24318-8748-E811-8126-005056B00B08';
    public static SM: string = 'EF8A7E11-8E60-E711-AB5D-005056B00B08';
    public static PatientConnect: string = '1D339FA8-619F-442C-B7B0-84DABA37FE89';
    public static Lab: string = '8EC16FC9-9C1B-E811-83A5-005056B00B08';
    public static KitPackAdmin: string = 'C2B54141-8755-E711-A6BB-005056B00B08';
    public static KitMaterialsManager: string = '3CB6E9F6-C63D-EA11-B484-005056B00B08';
    public static KitPacker: string = 'EE8A7E11-8E60-E711-AB5D-005056B00B08';
    public static SampleManagement: string = 'EF8A7E11-8E60-E711-AB5D-005056B00B08';
    public static ScientificAffairs: string = '6EE0F797-9C1B-E811-83A5-005056B00B08';
    public static ProposalDevelopment: string = '8EC067B9-9C1B-E811-83A5-005056B00B08';
    public static Marketing: string = '439327B8-7C0A-E911-BFF3-005056B00B08';
    public static DataAnalyst: string = '41A68BF8-2305-E911-93A5-005056B02CB4';
    public static InvoiceSpcialist: string = '40A68BF8-2305-E911-93A5-005056B02CB4';
    public static PMBioA: string = 'CDA2C856-4396-E911-BB30-005056B00B08';
    public static LabContact: string='7D685B11-5132-EB11-B39C-005056B02CB4';
    public static TransportTeam: string='D468C1C4-0B4D-4985-8DF4-7F55E0E907FB';
    public static DiagnosticPhysician: string = '1C44095B-D67F-EA11-AE47-005056B02CB4';
    public static BioAPlanner: string = 'B87DBC86-4396-E911-BB30-005056B00B08';
    public static SponsorTeam: string = '8EA5FCD5-9C1B-E811-83A5-005056B00B08';
    public static SampleManagementBioA: string = '775F7277-4396-E911-BB30-005056B00B08';
    public static KitPackerExternal: string = 'DC5A97B2-65F4-408E-A9DD-15597DCE877E';
    public static KitPackerExternalAdmin: string = '699AE716-ED1B-4637-849F-789C5BCC1E8F';
    public static DCS: string = '91C3C0F4-6C1B-4EB8-8139-C1601763297C';
    public static ExternalAdministrator: string = '948B2101-B712-EF11-892F-005056968DC4';
}

export class IMSModules {
    public static Home: string = '645E26CB-75D6-E611-9F36-005056B00B08';
    public static Admin: string = '655E26CB-75D6-E611-9F36-005056B00B08';
    public static InternalUsers: string = '665E26CB-75D6-E611-9F36-005056B00B08';
    public static ExternalUsers: string = '675E26CB-75D6-E611-9F36-005056B00B08';
    public static MyContacts: string = '685E26CB-75D6-E611-9F36-005056B00B08';
    public static SponsorContact: string = '695E26CB-75D6-E611-9F36-005056B00B08';
    public static InvestigatorSite: string = '6A5E26CB-75D6-E611-9F36-005056B00B08';
    public static ClinicalTeam: string = '6B5E26CB-75D6-E611-9F36-005056B00B08';
    public static Study: string = '6C5E26CB-75D6-E611-9F36-005056B00B08';
    public static MyStudies: string = '6D5E26CB-75D6-E611-9F36-005056B00B08';
    public static StudyFiles: string = '6E5E26CB-75D6-E611-9F36-005056B00B08';
    public static AuditReport: string = '6F5E26CB-75D6-E611-9F36-005056B00B08';
    public static ManageLinkedSites: string = '8E419AB8-FA8B-E711-BD2E-005056B00B08';
    public static ManageStabilityWorkFile: string = '7545AB12-F791-E711-9287-005056B00B08';
    public static KitManagement: string = '1785B997-7921-EA11-B484-005056B00B08';
    public static BomQcPsm: string = '1885B997-7921-EA11-B484-005056B00B08';
    public static BomQcKp: string = '1985B997-7921-EA11-B484-005056B00B08';
    public static GlobalArticles = '2085B997-7921-EA11-B484-005056B00B08';
}

export class EzMoneyModules {
    public static Specimen: string = 'E8D5B88A-D243-E811-8126-005056B00B08';
    public static Cover: string = 'B03B4853-AF43-E811-9B2B-005056B02CB4';
    public static Timeline: string = 'B13B4853-AF43-E811-9B2B-005056B02CB4';
    public static AssessmentSchedule: string = 'B23B4853-AF43-E811-9B2B-005056B02CB4';
    public static StartUp: string = 'B33B4853-AF43-E811-9B2B-005056B02CB4';
    public static Speciality: string = 'B43B4853-AF43-E811-9B2B-005056B02CB4';
    public static PM: string = 'B53B4853-AF43-E811-9B2B-005056B02CB4';
    public static Analysis: string = 'B63B4853-AF43-E811-9B2B-005056B02CB4';
    public static Kits: string = 'B73B4853-AF43-E811-9B2B-005056B02CB4';
    public static Outbound: string = 'B83B4853-AF43-E811-9B2B-005056B02CB4';
    public static Inbound: string = 'B93B4853-AF43-E811-9B2B-005056B02CB4';
    public static CentralLab: string = 'BA3B4853-AF43-E811-9B2B-005056B02CB4';
    public static RentalFees: string = 'BB3B4853-AF43-E811-9B2B-005056B02CB4';
    public static Assumption: string = 'BC3B4853-AF43-E811-9B2B-005056B02CB4';
    public static BudgetSummary: string = 'BD3B4853-AF43-E811-9B2B-005056B02CB4';
    public static Variance: string = 'BE3B4853-AF43-E811-9B2B-005056B02CB4';
    public static Parameters: string = 'BF3B4853-AF43-E811-9B2B-005056B02CB4';
    public static QuotationStatus: string = 'E9D5B88A-D243-E811-8126-005056B00B08';
    public static Transportaion: string = '5BF8C2EE-5ACD-E811-B828-005056B00B08';
    public static DM: string = '74036144-37B3-4560-B0A2-473659CEE3E9';
    public static PBMC: string = '1da9619c-7365-4122-8e08-be7275a896df';
    public static BioMarker: string = '4B5FBEC4-E7FD-4D58-9FFE-92AE65B3C3ED';
}

export class EzoneyCurrency {

    public static China: string = '909BDB6B-1B22-E811-88E9-005056B00B08';
    public static Singapore: string = '919BDB6B-1B22-E811-88E9-005056B00B08';
    public static USDollar: string = '929BDB6B-1B22-E811-88E9-005056B00B08';
    public static Euro: string = '939BDB6B-1B22-E811-88E9-005056B00B08';
}
export class EzmoneyVatdetails {
    public static TradeRegister: string = '20049450';
    public static VatNumber: string = 'NL0078.26.850.B01';
}
export class EzoneyCountry {

    public static Americas: string = '46D7D5D5-942E-E811-88E9-005056B00B08';
    public static Europe: string = '47D7D5D5-942E-E811-88E9-005056B00B08';
    public static Singapore: string = '48D7D5D5-942E-E811-88E9-005056B00B08';
    public static China: string = '49D7D5D5-942E-E811-88E9-005056B00B08';
}
export class EzoneyBankLocation {

    public static USA: string = 'ECD53C36-74ED-E811-9C1A-005056B00B05';
    public static Europe: string = 'EDD53C36-74ED-E811-9C1A-005056B00B07';
    public static Singapore: string = '1C2AA091-7BC2-4E2F-89D4-E3457612667D';
    public static China: string = '4757A778-58F4-40C8-A547-A05A9BD95541';
}
export class EzoneyBankVatPercent {

    public static USA: number = 21;
    public static Europe: number = 21;
    public static Singapore: number = 7;
    public static China: number = 6;
}
export class Feature {
    public static Dashboard: string = 'F05D4450-3CE8-E611-9F36-005056B00B08';
    public static ManageInternalUsers: string = '409D7D5F-3CE8-E611-9F36-005056B00B08';
    public static ManageExternalUsers: string = '90545067-3CE8-E611-9F36-005056B00B08';
    public static ManageUserManuals: string = 'D1D77D21-9FE8-E811-9C1A-005056B00B08';
    public static StudyOverview: string = '80913370-3CE8-E611-9F36-005056B00B08';
    public static ManageInvestigatorSites: string = 'D046067F-3CE8-E611-9F36-005056B00B08';
    public static ManageLabs: string = 'DF4A4527-2E32-4DAC-8F76-9113F59936C0';
    public static ManageContacts: string = 'C0331F9D-3CE8-E611-9F36-005056B00B08';
    public static MyStudyOverview: string = '006F08A7-3CE8-E611-9F36-005056B00B08';
    public static StudyDetails: string = '44C17AB0-3CE8-E611-9F36-005056B00B08';
    public static StudyContactsOverview: string = '9B08A9C7-3CE8-E611-9F36-005056B00B08';
    public static StudyFiles: string = '44A315CE-3CE8-E611-9F36-005056B00B08';
    public static ManageStudyFiles: string = '7CA3A594-C2EA-E611-9F36-005056B00B08';
    public static MyCommunicationPref: string = 'DD071DCD-C8F4-E611-8DB3-005056B00B08';
    public static StudySetup: string = '9197F272-242F-E711-B57D-005056B00B08';
    public static ManageRequisitions: string = '490A2976-9860-E711-AB5D-005056B00B08';
    public static ManageSpecimens: string = '4C3B32F3-B471-E711-BD2E-005056B00B08';
    public static ManageLinkedSites: string = '0A2F8AD2-FF8B-E711-BD2E-005056B00B08';
    public static Files: string = '39546551-64AE-E711-A836-005056B00B08';
    public static ManageStabilityWorkFiles: string = '75C70912-C2AC-E711-A836-005056B00B08';
    public static AlertReportMailSubjectConfig: string = '62DBC41D-D4CD-E711-B785-005056B00B08';
    public static ManageOrders: string = 'E0826493-275B-E711-AB5D-005056B00B08';
    public static StudyContactDataAccess: string = '63DBC41D-D4CD-E711-B785-005056B00B08';
    public static ContactReportRoutingPrefOverview: string = '705E929E-8915-E811-83A5-005056B00B08';
    public static HideUndoHideReportFeature: string = 'E37F098F-6C32-E811-88E9-005056B00B08';
    public static HideUndoHideResultFeature: string = '35D34411-2A3E-E811-8126-005056B00B08';
    public static SpecimenDescriptionsFeature: string = '1774B609-725F-E811-B810-005056B00B08';
    public static OptionalTestsFeature: string = '1874B609-725F-E811-B810-005056B00B08';

    public static VersionQuotation: string = 'D9A7486D-F24D-E811-9251-005056B00B08';
    public static AmendQuotation: string = 'DAA7486D-F24D-E811-9251-005056B00B08';
    public static CloneQuotation: string = 'DBA7486D-F24D-E811-9251-005056B00B08';
    public static GenerateDraftQuotation: string = 'DCA7486D-F24D-E811-9251-005056B00B08';
    public static ShowPortalAccess: string = '5AC555EA-6DB6-E811-B828-005056B00B08';
    public static ManageIsolateRequisitions: string = '2737D5AA-213B-E911-B956-005056B00B08';
    public static ViewUnBlindedDataFlag: string = '37B51DFA-7F45-E911-B956-005056B00B08';
    public static CommunicationPreferenceDetails: string = '8113A53D-E04B-E911-B956-005056B00B08';
    public static SponsorContactEcomAccess: string = '10DE00A6-26D9-E911-8952-005056B02CB4';

    public static OnlyAdminAccess: string = 'F7BC7028-0E02-EA11-A1A7-005056B00B08';
    public static ViewSMLabOrderHistory: string = 'E1B1E41E-F690-EA11-AE47-005056B02CB4';
    public static ViewSMLabOrderErrors: string = 'E2B1E41E-F690-EA11-AE47-005056B02CB4';
    public static ScanningPageAccess: string = 'E3B1E41E-F690-EA11-AE47-005056B02CB4';
    public static ViewSMLabOrderDetails: string = 'E4B1E41E-F690-EA11-AE47-005056B02CB4';
    public static GlobalArticleWriteAccess: string = 'F7BC7048-0E02-EA11-A1A7-005056B00B08';
    public static KitReviewDynamicColumns: string = '0A4CF0F6-7B77-EA11-B484-005056B00B08';
    public static CreateKit: string = '90DC99A5-0C7B-EA11-B484-005056B00B08'

    public static EnableKitTransfer: string = 'B5236108-4964-EA11-B484-005056B00B08';
    public static TestManagement: string = '41921823-CB56-EA11-B484-005056B00B08';
    public static ProtocolTestLink: string = '40921823-CB56-EA11-B484-005056B00B08';

    public static ParcelRegistration: string = 'DE4A4527-2E32-4DAC-8F76-9113F59936C0';
    public static ManageStudyException: string = '79B28CCA-1CE6-EA11-A32D-005056B00B08';
    public static AssignQueriesId: string = '255A3251-E2A8-EB11-93CC-00505696833B';

    public static CreateCancelOrderId: string = 'DBFE2155-C496-4BD0-8E1C-02B56F656BA7';

    public static VisitAdditionalFields: string = '8092E109-B550-4187-B6B7-7E61AC116CDF';
    public static SIMSPriming: string = 'EF2A113E-D928-4726-99F5-26693EB36D23';
    public static MatrixCode: string = 'BB9C423F-B2D6-4B6F-A90D-EADA6056CFF4';
    public static IntendedUse: string = '4A26571C-D7F1-4920-9AC2-2E28B726C079';
    public static SampleWorkFlow: string = '636C6457-A4FC-4E91-BCFF-B3AEC10D6665';
    public static ShipmentDestination: string = 'C91190B0-91B7-4D50-B4EE-37AFECF0313F';
    public static StudyShipmentDestinationMapping: string = 'E2C30693-DEB9-495C-B48A-DFE68FA170C0';
    public static StorageInvoicingFlagAccess: string = 'BC9C423F-B2D6-4B6F-A90D-EADA6056CFF4';
}

export class StudyStatus {
    public static Active: string = '642a10a2-7cd3-e611-9f36-005056b00b08';
    public static Closed: string = '652a10a2-7Cd3-e611-9f36-005056b00b08';
    public static Draft: string = '662a10a2-7cd3-e611-9f36-005056b00b08';
    public static Cancelled: string = '852a10a2-7cd3-e611-9f36-005056b00b08';
    public static Completed: string = '862a10a2-7cd3-e611-9f36-005056b00b08';
    public static OnHold: string = '872a10a2-7cd3-e611-9f36-005056b00b08';
}

export class StudySetupVersionStatus {
    public static Draft: string = '7EDB66F3-BE24-E711-B57D-005056B00B08';
    public static Active: string = '7FDB66F3-BE24-E711-B57D-005056B00B08';
    public static Completed: string = '80DB66F3-BE24-E711-B57D-005056B00B08';
    public static Cancelled: string = '81DB66F3-BE24-E711-B57D-005056B00B08';
    public static ReadyForQC: string = '7ECC1D48-D92E-E911-B956-005056B00B08';
}

export class QuotationStatus {
    public static Draft: string = '44524269-9d39-e811-8126-005056b00b08';
    public static InReview: string = '45524269-9d39-e811-8126-005056b00b08';
    public static Approved: string = '46524269-9d39-e811-8126-005056b00b08';
    public static Awarded: string = '47524269-9d39-e811-8126-005056b00b08';
}
export class InvoiceStatus {
    public static InProgress: string = '56F8C2EE-5ACD-E811-B828-005056B00B08';
    public static Draft: string = '57F8C2EE-5ACD-E811-B828-005056B00B08';
    public static InReview: string = '58F8C2EE-5ACD-E811-B828-005056B00B08';
    public static Approved: string = '59F8C2EE-5ACD-E811-B828-005056B00B08';
    public static SentToSponsor: string = '5AF8C2EE-5ACD-E811-B828-005056B00B08';
    public static Rejected:string ='57F8C2EE-5ACD-E811-B828-005056B00B08'
}
export class AccountReceivableTypeList {
    public static Complete: string = '858007D1-71ED-E811-9C1A-005056B00B08';
    public static Partial: string = '868007D1-71ED-E811-9C1A-005056B00B08';
}
export class RoleList {
    public static ProposalDevelopment: string = '8ec067b9-9c1b-e811-83a5-005056b00b08';
    public static InternalReviewer: string = '4ddb3199-6948-e811-8126-005056b00b08';
    public static BusinessDevelopment: string = '4edb3199-6948-e811-8126-005056b00b08';
    public static ProjectManager: string = '53b5aefe-3fe8-e611-9f36-005056b00b08';
    public static InovicingSpecialist: string = '40a68bf8-2305-e911-93a5-005056b02cb4';
    public static IncvoiceSpecialist: string = '40a68bf8-2305-e911-93a5-005056b02cb4';
    public static DatatEntry: string = '4fdb3199-6948-e811-8126-005056b00b08';
    public static DataAnalyst: string = 'e4309e3c-80ee-e811-9c1a-005056b00b08';
    public static Admin: string = '4a2a10a2-7cd3-e611-9f36-005056b00b08';
    //public static DatatEntry: string = '4fdb3199-6948-e811-8126-005056b00b08';

    //39d7075b-70d6-e611-9f36-005056b00b08
}

export class DataCaptureFieldIds {
    public static SubjectCode: string = '59c01eec-a729-e711-b57d-005056b00b08';
    public static CollectionDate: string = 'fcf4dad7-3b2a-e711-b57d-005056b00b08';
    public static CollectionTime: string = 'fdf4dad7-3b2a-e711-b57d-005056b00b08';
    public static Gender: string = 'b78c18c4-392a-e711-b57d-005056b00b08';
    public static DateofBirth: string = 'b48c18c4-392a-e711-b57d-005056b00b08';
    public static EthnicOrigin: string = 'b58c18c4-392a-e711-b57d-005056b00b08';
    public static Initials: string = 'b68c18c4-392a-e711-b57d-005056b00b08';
    public static FastingStatus: string = '04f5dad7-3b2a-e711-b57d-005056b00b08';
    public static ListDummy21: string = '3c393851-e2b3-e711-a836-005056b00b08';
    public static ScreeningCode: string = '5ac01eec-a729-e711-b57d-005056b00b08';
    public static RandomizationCode: string = '5BC01EEC-A729-E711-B57D-005056B00B08';
    public static Fasting: string = 'ee010519-40e1-ea11-a32d-005056b00b08';
    
    // AOE Fields
    public static FirstTest: string = 'ef010519-40e1-ea11-a32d-005056b00b08';
    public static EmployedInHealthcare: string = 'f0010519-40e1-ea11-a32d-005056b00b08';
    public static SymptomaticPerCDC: string = 'f1010519-40e1-ea11-a32d-005056b00b08';
    public static HospitalizedAtTimeOfTesting: string = 'f2010519-40e1-ea11-a32d-005056b00b08';
    public static ICUatTimeOfTesting: string = 'f3010519-40e1-ea11-a32d-005056b00b08';
    public static ResidentInCongregateCareOrLivingSetting: string = 'f4010519-40e1-ea11-a32d-005056b00b08';
    public static Pregnant: string = 'f5010519-40e1-ea11-a32d-005056b00b08';
    public static DateOfSymptomOnset: string = 'f6010519-40e1-ea11-a32d-005056b00b08';
}
export class StudyReportType {
    public static ClinicalReport: string = '582A10A2-7CD3-E611-9F36-005056B00B08';
    public static AlertReport: string = '572A10A2-7CD3-E611-9F36-005056B00B08';
}
export class Defaults {
    public static EmptyGuid: string = '00000000-0000-0000-0000-000000000000';
}
export class Constants {
    static readonly SHORT_DATE_FMT = 'dd-MMM-yyyy';
    static readonly DATE_TIME_FMT = `${Constants.SHORT_DATE_FMT} hh:mm:ss`;
}
export class SpecimenLevel {
    public static SpecimenLevelVisit: string = 'FEBF48A1-13FC-4EE2-935B-EF215CEEF852';
    public static SpecimenLevelShipment: string = 'AF8433A8-ED57-47AF-8D55-6AE5AB8CDB3B';
}

export class StudyReportStatus {
    public static Pending: string = '53178277-a431-e811-88e9-005056b00b08';
    public static Exported: string = '54178277-a431-e811-88e9-005056b00b08';
    public static Completed: string = '55178277-a431-e811-88e9-005056b00b08';
    public static Failed: string = '56178277-a431-e811-88e9-005056b00b08';
    public static OnHold: string = '177a2c02-a0e1-e811-9c1a-005056b00b08';
    public static Deferred: string = '2d1f8a75-3cfd-e811-bff3-005056b00b08';
}

export class SpecimenType {
    public static Standard: string = '778A317D-AA33-E711-B57D-005056B00B08';
    public static Backup: string = '788A317D-AA33-E711-B57D-005056B00B08';
    public static NoHandling: string = '798A317D-AA33-E711-B57D-005056B00B08';
}

export class KitPackingLocations {
    public static Louisville: string = 'E118F19E-E454-E711-A6BB-005056B00B08'
    public static Oosterhout: string = 'E018F19E-E454-E711-A6BB-005056B00B08'
    public static Shanghai: string = '1F79047D-FC82-E911-A19C-005056B00B08'
}
export class BomStatus {
    public static Draft: string = '5D821E5C-A03C-F811-8126-005056B00B08'
    public static RejectedPsm: string = 'F13979BB-C9D0-EA11-A32D-005056B00B08'
    public static PendingApprovalPsm: string = '5B821E5C-A03C-F831-8126-005056B00B08'
    public static PendingApprrovalKp = '5C821E5C-A03C-F851-8126-005056B00B08'
    public static RejectedKp = '5E821E5C-A03C-F871-8126-005056B00B08'
    public static Live = '5F821E5C-A03C-F891-8126-005056B00B08'
}

/**
 * Used to display order status for Clear Clix orders.
 * Mapping between Fetch order status and Clear Clix order status :
 * 1. NULL -> Order Placed
 * 2. Pending Approval -> In Process
 * 3. New -> In Process
 * 4. Printed -> Being Packed
 * 5. Partially Printed -> Being Packed
 * 6. Print requested -> Being Packed
 * 7. Cancelled -> Cancelled
 * 8. Shipped -> Shipped
 */
export var clearClixOrderStatus: string[] = [
    'Order Placed',
    'In Process',
    'Being Packed',
    'Cancelled',
    'Shipped'
]

/**
 * This is mainly used to clear the template cache
 */
export var templateVersion = "?v=" + Date.now();

export var generalLabs = ["BREDA", "SINGAPORE", "SHANGHAI"];

export class ParcelStatusId {
    public static DiscrepantId: string = "5b271db5-4c5c-e911-bb4a-005056b00b08";
    public static RegisteredId: string = "8fd5a373-4c5c-e911-bb4a-005056b00b08";
}

export class SampleRegistrationStatus {
    public static InitiateSecondEntry: string = "f31b1ea2-596d-e911-93f8-005056b00b08";
    public static RegistrationCompleted: string = "f41b1ea2-596d-e911-93f8-005056b00b08";
}
export class LabelLayout {
    public static TubeLabel: string = "7b6cfb72-ba82-e911-a19c-005056b00b08";
}
export class Courier {
    public static TNT: string = "7A2CBC14-9066-E911-AC44-005056B02CB4";
    public static TNTawbLength: number = 28;
    public static UPS: string = "8A2CBC14-9066-E911-AC44-005056B02CB4";
    public static UPSawbLength: number = 18;
    public static FEDEX: string = "7C2CBC14-9066-E911-AC44-005056B02CB4";
}
export class Country {
    public static US: string = "EC62EA72-FBD6-E611-9F36-005056B00B08";
}
export class Lab {
    public static Breda: string = "6B881869-6E3F-E711-99A1-005056B00B08";
    public static Lancaster: string = "6C881869-6E3F-E711-99A1-005056B00B08";
    public static Singapore: string = "6D881869-6E3F-E711-99A1-005056B00B08";
    public static Shanghai: string = "6E881869-6E3F-E711-99A1-005056B00B08";
    public static Lancaster_NonUS: string = "6F881869-6E3F-E711-99A1-005056B00B08";
}

export class VisitType {
    public static optionalVisitType: string = "Optional";
}

export class DefaultPrnterConfig{
    public static printerConfigId:string="9B3CC8CD-49F9-ED11-88C5-005056968DC4";
}

export class KitDistributionType {
    public static KitToSite: string = "360FD57D-BE24-E711-B57D-005056B00B08";
    public static KitToParticipant: string = "370FD57D-BE24-E711-B57D-005056B00B08";
}

export class KitPackingLocation {
    public static Louisville: string = "E118F19E-E454-E711-A6BB-005056B00B08";
    public static Oosterhout: string = "E018F19E-E454-E711-A6BB-005056B00B08";
    public static Shanghai: string = "1F79047D-FC82-E911-A19C-005056B00B08";
    public static TennantUs: string = "E218F19E-E454-E711-A6BB-005056B00B08";
}

export class OrderStatus {
    public static New: string = "2032272E-E454-E711-A6BB-005056B00B08";
    public static Shipped: string = "42DCAB13-286F-EA11-AE47-005056B02CB4";
    public static Cancelled: string = "2232272E-E454-E711-A6BB-005056B00B08";
}

export class VisitOrderingType {
    public static SequentialReOrder: number = 1;
    public static ManualReOrder: number = 2;
    public static DragReOrder: number = 3;
    public static PositionNReorder: number = 4;
}


export class TestCategoryList {
    public static Test: string = 'D0020959-F2D7-4380-AE7F-827865E713E0';
    public static Panel: string = '3C27D2BC-2C91-4803-AC36-C4ACD8214E0D';
}
export class TestAvailableType {
    public static General: string = '88EE6E80-05CD-4B2E-B856-8C507C600F0D';
    public static Sponsor: string = '9C80BABA-A0BF-4774-896B-D52859A92B65';
    public static CRO: string = 'A129D319-C231-4F6D-B880-C70B64BFBCD1';
}
export class ProtocolTestLinkType {
    public static Sponsor: string = 'EAF34598-DFB3-4625-987F-8AD59B0C5646';
    public static CRO: string = 'E1DE72CF-14CD-45BE-8092-CB8E65D9E6ED';
}

export class TestResultCharacteristicType {
    public static Quantitative: string = 'C667BC37-F1E3-408E-AD35-2A3A2D525C31';
    public static Qualitative: string = '1B9D11B6-4B04-4141-8207-96DBA373DCF3';
}
export class GenderType {
    public static Male: string = '1A8CC006-9C22-4495-A3B4-59897F59645F';
    public static Female: string = '8BEB3BB3-AC25-4717-BD99-E6857BD331F2';
    public static NotApplicable: string = '015E23DE-3E3B-4DE3-B69D-4D12F347BD28';
    public static Both: string = '9CE3F317-04F1-4D89-9D8D-BE36C8AD76F2';
}
export class AgeUnits {
    public static Days: string = 'D3D75F90-8429-E711-B57D-005056B00B08';
    public static Months: string = 'D4D75F90-8429-E711-B57D-005056B00B08';
    public static Years: string = 'D5D75F90-8429-E711-B57D-005056B00B08';
    public static Weeks: string = 'EB56CB8A-672A-E711-B57D-005056B00B08';
}
export class EthnicOrigin {
    public static American: [string, string] = ['American Indian or Alaska Native', 'F7F6B8B1-7D29-E711-B57D-005056B00B08'];
    public static Asian: string = 'F8F6B8B1-7D29-E711-B57D-005056B00B08';
    public static Black: [string, string] = ['Black or African American', 'F9F6B8B1-7D29-E711-B57D-005056B00B08'];
    public static Hispanic: [string, string] = ['Hispanic or Latino', 'FAF6B8B1-7D29-E711-B57D-005056B00B08'];
    public static Native: [string, string] = ['Native Hawaiian or Other Pacific Islander', 'FBF6B8B1-7D29-E711-B57D-005056B00B08'];
    public static White: string = 'FCF6B8B1-7D29-E711-B57D-005056B00B08';
    public static Others: string = 'FDF6B8B1-7D29-E711-B57D-005056B00B08';
}
export class FastingStatusType {
    public static No: string = '0';
    public static Yes: string = '1';
}
export class SpecimenInstructionType {
    public static ShippingInstruction: string = 'FCB0D9B5-3760-4AB4-B936-3F9F6F34912E';
    public static CollectionInstruction: string = 'ED491C05-500E-4B09-BBA5-452E604EAC2B';
}
export class ShippingConditionType {
    public static Ambient: string = 'ECE5A581-FBD1-4A55-9733-E8DA8CC76718';
    public static Cooled: string = 'EE4F3307-73D6-466A-B17A-F94A0E0C7B08';
    public static Twenty: string = '0047A25D-70FC-4BD5-9D59-00237A7B6AD8';
    public static Eighty: string = 'A2E74FA6-14C9-4A43-8E13-6DE973812A9F';
    public static OneNintySix: string = '3D891157-0D41-49EA-9FFD-943E8E997666';
}


export class UnitTypeList {
    public static SIUnitType: string = 'D90AC88D-3C40-487F-9BB0-954E6C11D75F';
    public static ConventionalUnitType: string = '763402F9-4455-428D-AFF2-3CCD9EBBF2B6';
}

export class CalculationType {
    public static No: string = '0';
    public static Yes: string = '1';
}

export class CatalogueTATType {
    public static Hours: string = '45E9D4CC-CB48-EA11-B484-005056B00B08';
    public static Days: string = '46E9D4CC-CB48-EA11-B484-005056B00B08';
}

export class TestStatus {
    public static Proposed: string = '808637F0-1347-EA11-AE47-005056B02CB4';
    public static UnderValidation: string = '818637F0-1347-EA11-AE47-005056B02CB4';
    public static Active: string = '828637F0-1347-EA11-AE47-005056B02CB4';
    public static ValidationExpired: string = '838637F0-1347-EA11-AE47-005056B02CB4';
}

export class DiscrepancyCustomName {
    public static DDepartmet: string = "D Dept";
    public static SubjectCode: string = "SubjectCode";
    public static CURResult: string = "CUR Result";
    public static ScreeningCode: string = "Screening Code"
}

export class ItemSource {
    public static DataSync: string = '94C33CBB-FCD8-E811-9C1A-005056B00B08';
    public static UserEntry: string = '95C33CBB-FCD8-E811-9C1A-005056B00B08';
    public static FileUpload: string = '96C33CBB-FCD8-E811-9C1A-005056B00B08';
    public static StudyQuote: string = '97C33CBB-FCD8-E811-9C1A-005056B00B08';
}

export class RecurrenceType {
    public static Weekly: string = '57A9E03B-569A-EA11-AE47-005056B02CB4';
    public static Monthy: string = '58A9E03B-569A-EA11-AE47-005056B02CB4';
    public static Yearly: string = '59A9E03B-569A-EA11-AE47-005056B02CB4';
}

export class SMRegistrationType {
    public static Repeat: string = 'D3B58C62-BF69-EA11-B684-005056B00B08';
    public static LateArrival: string = 'D4B58C62-BF69-EA11-B884-005056B00B08';
    public static Unscheduled: string = 'D5B58C62-BF69-EA11-B464-005056B00B08';
    public static Unknown: string = 'DBB58C62-BF69-EA11-B884-005056B00B08';
}
export class ReqFieldCategory {
    public static VisitParameters: string = '7FF009BC-7A29-E711-B57D-005056B00B08';
}

export class Reasons {
    public static Reason1: [string, string] = ["Demographic mismatch with CAII", "isSubjectMismatch"];
    public static Reason2: [string, string] = ["Missing requisition fields", "YLab"];
    public static Reason3: [string, string] = ["Missing R department Test Results", "isAnyResultMissing"];
}

export class SymptomaticPerCDC {
    public static Y: string = '690D5913-8512-EB11-8600-005056B02CB4';
    public static N: string = '6A0D5913-8512-EB11-8600-005056B02CB4';
    public static U: string = '6B0D5913-8512-EB11-8600-005056B02CB4';
}

export class Currencies {
    public static EUR: string = '939bdb6b-1b22-e811-88e9-005056b00b08';
    public static USD: string = '929bdb6b-1b22-e811-88e9-005056b00b08';
    public static CNY: string = '909bdb6b-1b22-e811-88e9-005056b00b08';
    public static SGD = '919bdb6b-1b22-e811-88e9-005056b00b08';

}

export class SMRepeatVisitType {
    public static Repeat: string = '0B8FD7AC-BF74-EB11-93C6-00505696833B';
    public static Unknown: string = '0C8FD7AC-BF74-EB11-93C6-00505696833B';
}

export class SiteShipmentCondition {
    public static Ambient: string = '9d395809-ae33-e711-b57d-005056b00b08';
    public static Frozen: string = '9f395809-ae33-e711-b57d-005056b00b08';
}

export class HandleType {
    public static Ambient: string = '4E3B7C9D-B6A4-EA11-AE47-005056B02CB4';
    public static Frozen: string = '4F3B7C9D-B6A4-EA11-AE47-005056B02CB4';
}

export class QueryStatus{
    public static Open: string = '2CEBD4A1-B108-EB11-93DC-005056B00B08'
    public static Incomplete: string = 'EDC31645-4509-EB11-93DC-005056B00B08'
    public static Closed: string = '4BDD78CE-F309-EB11-93DC-005056B00B08'
    public static CommunicatedInt: string = 'E112B7B8-E318-EB11-AD25-005056B00B08'
    public static CommunicatedExt: string = 'E212B7B8-E318-EB11-AD25-005056B00B08'
    public static Hold: string = 'E312B7B8-E318-EB11-AD25-005056B00B08'
    public static Escalated: string = 'E412B7B8-E318-EB11-AD25-005056B00B08'
    public static ReadyForReview: string = 'E512B7B8-E318-EB11-AD25-005056B00B08'
    public static ReadyToProcess: string = 'E612B7B8-E318-EB11-AD25-005056B00B08'
}

export class MasterDataScreen {
  public static MatrixCode: string = 'MATRIXCODE';
  public static SampleDestination: string = 'SAMPLEDESTINATION';
  public static ShipmentDestination: string = 'SHIPMENTDESTINATION';
  public static ShipmentDestinationContact: string = 'SHIPMENTDESTINATIONCONTACT';
  public static SpecimenDescription: string = 'SPECIMENDESCRIPTION';
}

export class StudyPrimingQCStatus {
  public static Active: string = 'Active';
  public static In_Active: string = 'Inactive';
  public static QCRejected: string = 'QC Rejected';
  public static Draft: string = 'Draft';
}

export class ToxicityReportConfigStatus{
    public static Active: string = '8D1C0334-D5B0-4C77-A5A7-9E5583AD3EDF';
  public static Inactive: string = '8E1C0334-D5B0-4C77-A5A7-9E5583AD3EDF';
  public static Draft: string = '8C1C0334-D5B0-4C77-A5A7-9E5583AD3EDF';
}


export class passwordEncryption{
  public static passwordEncryptionKey: string = 'a1b2c3d4e5f6g7h8';
  public static passwordEncryptionIV: string = '8h7g6f5e4d3c2b1a';
  public static applyEncription: boolean = true;
}

export class genericConfigValues{
  public static systemAdminEmailId: string = 'in01_fetchadmin@eurofins.com';
  public static captchaSiteKey: string = '6Lc2mk4cAAAAAKIL11jVbJEnxtVwGrLPcNBHakPe';
  public static clientPortal: boolean = false;
  public static geocodeClientKey: string = 'AIzaSyB5HfnKN5DKvWcZDRW6ETCBW7vR49MY9mw';
  public static geocodeUrl: string = 'https://maps.googleapis.com/maps/api/geocode/';
  public static useGeocode: boolean = true;
  public static AllowedMaxQuantityInCtmConfigAndKitOrders: any = 200;
  public static maximumStockItems: number = 10;
  public static sessionTimeOut: any = 60;
  public static characterLimit: number = 15;
  public static subjectIdCharacterLimit: number = 20;
  public static setTimeOutValue: number = 100;
  public static isStagingEnvironment: boolean = true;
  public static privacyPolicyLink: any = 'https://eurofinscentrallaboratory.com/privacy-policy/';
}



 

 
import { NgModule, APP_INITIALIZER, ErrorHandler, ApplicationRef, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule, Headers, Http, BaseRequestOptions, RequestOptions } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

import {AutoCompleteModule} from 'primeng/autocomplete';


import { LoginModule } from './Authentication/Login/login.module';
//import { RegisterModule } from './register/register.module';
import { ForgotPasswordModule } from './Authentication/ForgotPassword/forgotpassword.module';
import { ResetPasswordModule } from './Authentication/ResetPassword/resetpassword.module';
import { ChangePasswordModule } from './Authentication/ChangePassword/changepassword.module';
//import { LandingPageModule } from './landingpage/landingpage.module';
//import { TermsAndConditionModule } from './TermsandCondition/termsandcondition.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './shared/auth.guard';
import { RolesGuard } from './shared/roles.gaurd';
import { HttpClient } from './shared/HttpClient';
import { LoginService } from './Authentication/Login/Services/login.service';
// import { Ng2IdleModule } from 'ng2-idle';
// import {Idle, DEFAULT_INTERRUPTSOURCES} from 'ng2-idle';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfigService } from './config/ConfigService';
import { BlockUIService } from './shared/services/blockui.service';
import { NotificationService } from './shared/services/notification.service';
import { InlineEditorModule } from 'ng2-inline-editor';
//import { DashboardModule } from './Dashboard/dashboard.module';
//import { DashboardService } from './Dashboard/Services/dashboard.service';
import { GlobalVariableServices } from './shared/sharedservices/globalvariable.services';
import { EmailValidatorDirective } from './shared/validators/email.validator';
import { RolesPermissionsService } from './shared/services/rolespermissions.service';
import { ReadPermissionDirective } from './shared/directives/readpermission.directive';
import { SetUpVersionReadOnlyDirective } from './shared/directives/setupversionreadonly.directive';
import { ShortDatePipe } from './shared/pipes/shortdate.pipe';
import { GlobalExceptionHandler } from './shared/BaseComponent/FetchErrorHandler';
import { QuotationReadOnlyDirective } from './shared/directives/quotationreadonly.directive';
import { InvoiceReadOnlyDirective } from './shared/directives/invoicereadonly.directive';
import { AddCommentReadOnlyDirective } from './shared/directives/addcommentreadonly.directive';
import {ExternalUrlDirective} from './external-url.directive';
import 'rxjs/Rx';
/* Feature Modules */
import { routes } from './app.routing';
import { SessionService } from './shared/services/session.service';
//import { ToastsManager } from 'ngx-toastr/src/toast-manager';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { _ngReduxFactory } from '@angular-redux/store/lib/src/ng-redux.module';
// import { LandingPageModule } from './LandingPage/landingpage.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './EComPortal/ecommportal.reducer'
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { DBModule } from '@ngrx/db';
import { schema } from './db';
// import { LandingPageModule } from './LandingPage/landingpage.module';
import { CollectionEffects } from './EComPortal/effects/collection.effects'
import { OrderEffects } from './EComPortal/effects/order.effects'
//  import { DialogModule } from 'primeng/primeng';
import { InputTextareaModule, DataTableModule, ButtonModule,  DropdownModule, DialogModule, ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import {ChartModule} from 'primeng/chart';
import {DatePipe} from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VersionCheckService } from './HashPolling/version-check.service';
import {SponsorProtocolModule} from './AddProtocolSponsor/sponsorprotocol.module';
import {ProtocoltestlinkModule} from './ProtocolTestLink/protocoltestlink.module';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FileUploadService } from './shared/services/fileUpload.service';
import { TooltipModule } from 'primeng/tooltip';
import { SecurityInterceptor } from './Common/Interceptors/security.interceptor';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    InlineEditorModule,
    LoginModule,
    DialogModule,
    InputTextareaModule,
    // DashboardModule,
    //LandingPageModule,
    RouterModule.forRoot(routes, { useHash: true }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule,
    ChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    //  DialogModule,
    EffectsModule.forRoot([CollectionEffects, OrderEffects]),
    DBModule.provideDB(schema),
    AutoCompleteModule,
    TooltipModule,
  ],
  declarations: [ AppComponent, ExternalUrlDirective ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap:    [ AppComponent ],
   providers: [
        AuthGuard,
        RolesGuard,
        { provide: APP_BASE_HREF, useValue: '' },
        HttpClient,        
        LoginService,
        VersionCheckService,
        ConfigService,
        BlockUIService,
        NotificationService,
        //LandingPageModule,
        //DashboardService,
        NgReduxModule,
        GlobalVariableServices,
        RolesPermissionsService,
        FileUploadService,
        SessionService,
        [DatePipe],
        {provide: DevToolsExtension, useClass: DevToolsExtension, deps: [ApplicationRef, NgRedux]}, // add static provider
{ provide: NgRedux, useFactory: _ngReduxFactory, deps: [NgZone] },
        { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.load(), deps: [ConfigService], multi: true },
         {
        provide:ErrorHandler ,useClass:GlobalExceptionHandler
     },
     { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true }
    ],
})
export class AppModule { }

import * as collection from '../actions/order.actions';
import {OrderConfirmState} from '../ecommportal.state';
;
const initialstate: OrderConfirmState = {
    orderKey:'',
    orderNumber:'',
    selectedStudyId : '',
    selectedSiteId : '',
    lineItems : [],
    selectedAddress : null   ,
    comments:'',
    showPrice:true,
}

//reducer with empty initial state 
export function reducer(state = initialstate, action: collection.Actions): OrderConfirmState {

    switch (action.type) {
       
      
        case collection.LoadSuccess_Action:
        {
            const order = action.payload;
            var state2= Object.assign({}, state, order);
             return state2;

        }
         case collection.UpdateOrder_State:
         {
            const orderDetails = action.payload;
            var state1= Object.assign({}, state, orderDetails);
            return state1;

         }

        case collection.SaveOrderState_ToLocal:

    

        case collection.Clear_OrderState:
        {  
           return state=initialstate;
            
           
        }

        default:
        return state;
        }
    }
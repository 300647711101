
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import {systemjs} from 'systemjs';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', loadChildren: './Authentication/Login/login.module#LoginModule' },
   {path: 'termsandcondition', loadChildren: './TermsandCondition/termsandcondition.module#TermsAndConditionModule'},
  { path: 'home', loadChildren: './LandingPage/landingpage.module#LandingPageModule' },
  { path: 'forgotpassword', loadChildren: './Authentication/ForgotPassword/forgotpassword.module#ForgotPasswordModule' },
   {path: 'changepassword', loadChildren:'./Authentication/ChangePassword/changepassword.module#ChangePasswordModule'},
  {path: 'register', loadChildren:'./Register/register.module#RegisterModule'},
  {path: 'resetpassword', loadChildren:'./Authentication/ResetPassword/resetpassword.module#ResetPasswordModule'},
  {path: 'ack', loadChildren:'./SendAcknowledgment/sendacknowledgment.module#SendAcknowledgmentModule'},
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

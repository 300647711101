import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from '../../shared/globals';

@Pipe({
  name: 'shortdate'
})
export class ShortDatePipe implements PipeTransform {

  private datePipe: DatePipe = new DatePipe('en-US');
  
  transform(value: any, args?: any): any {     
    return this.datePipe.transform(value, Constants.SHORT_DATE_FMT);
  }
}
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config/ConfigService";

@Injectable()
export class SessionService {

    constructor(private config: ConfigService) {
    }
    getItem(input) {
        if ((input == 'loggedInUserId' || input == 'id_token' || input == 'isLoggedIn'
            || input == 'lastLoggedIn' || input == 'userPermissions' || input == 'isSuperUser' || input == 'userSponsorId'
            || input == 'IsUserKitPackAdmin' || input == 'isExternal' || input == 'roleId') && this.config.get('clientPortal')) {
            return localStorage.getItem(input);
        }
        return sessionStorage.getItem(input);
    }
    setItem(input, value) {
        sessionStorage.setItem(input, value);
    }

    clearAll() {
        sessionStorage.clear();
    }
    removeItem(input) {
        sessionStorage.removeItem(input);
    }

    get
}
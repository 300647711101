import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Globals from '../shared/globals';

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
   // private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

   isLoginscreenRefresh: any;

    constructor(private http: HttpClient) 
    {
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes  --1000 * 60 * 30
     */
    
    public initVersionCheck(url, isLoginscreen, frequency = 1000 * 60 * 1) {
       if(isLoginscreen)
       {
            this.checkVersion(url);
       }
       else //from app component.
       {
            setInterval(() => {
                this.checkVersion(url);
            }, frequency);
       } 
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .first()
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(localStorage.getItem('currentHash'), hash);

                    // If new version, do something
                    if (hashChanged) {
                        //alert('new version released');
                        var isLoggedin = Globals.authUser.isLoggedIn;

                        localStorage.setItem('currentHash', hash);
                        setTimeout(() => {
                            
                            if(isLoggedin)
                            {
                               alert("New version of the application is available. Kindly refresh the application.");
                            }
                            
                            location.reload(true);
                            //window.opener.location.href = window.opener.location;
                        }, 30); 
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    //this.globals.currentHash = hash;
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        // if (!currentHash || currentHash === '') {
        //     sessionStorage.setItem('currentHash', newHash);
        //     return false;
        // }

      //If localstorage item not found then its first time user so reload.
      if(!currentHash)
            return true;

        return currentHash !== newHash;
    }
}

﻿import { Injectable, EventEmitter } from '@angular/core';
@Injectable()
export class AuditTrialReasonService {
    public showpopEvent: EventEmitter<any>;
    public reasonIdEvent: EventEmitter<any>;
    public reasonEvent: EventEmitter<any>;
    constructor() {
        this.showpopEvent = new EventEmitter();
        this.reasonIdEvent = new EventEmitter();
        this.reasonEvent = new EventEmitter();
    }
}
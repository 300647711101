import { Directive, Input, TemplateRef, ViewContainerRef, Renderer } from '@angular/core';
import{ StudySetupVersionStatus } from '../globals'
@Directive({
    selector: '[setupVersionReadOnly]'
})

export class SetUpVersionReadOnlyDirective {
    @Input('setupVersionReadOnly') versionStatusId: string;
    constructor(private templateRef: TemplateRef<any>,
        private _renderer: Renderer,
        private viewContainer: ViewContainerRef) {
    }
    ngOnInit() {
        this.setStatusBasedView();
    }

    setStatusBasedView() {
        if (this.versionStatusId == StudySetupVersionStatus.Draft)
        {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            var view = this.viewContainer.createEmbeddedView(this.templateRef);
            let rootElem = view.rootNodes[0];
            
            if(rootElem) {
                this._renderer.setElementClass(rootElem, 'setupReadOnly', true);
            }
        }
    }
}

import { Directive, Input, TemplateRef, ViewContainerRef, Renderer } from '@angular/core';
import { QuotationStatus, RoleList } from '../globals'
import { SessionService } from "../services/session.service";
@Directive({
    selector: '[addcommentReadonly]'
})

export class AddCommentReadOnlyDirective {
    @Input('addcommentReadonly') quotationStatusId: string;
    constructor(private templateRef: TemplateRef<any>,
        private _renderer: Renderer,
        private sessionService: SessionService,
        private viewContainer: ViewContainerRef) {
    }
    ngOnInit() {
        this.setStatusBasedView();
    }

    setStatusBasedView() {
        //if (roleId == RoleList.ProposalDevelopment || roleId == RoleList.BusinessDevelopment || roleId == RoleList.InternalReviewer) {
            if (this.quotationStatusId == QuotationStatus.InReview) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                var roleId = this.sessionService.getItem('roleId');
                if (roleId == RoleList.ProposalDevelopment) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
                else {
                    var view = this.viewContainer.createEmbeddedView(this.templateRef);
                    let rootElem = view.rootNodes[0];

                    if (rootElem) {
                        this._renderer.setElementClass(rootElem, 'setupReadOnly', true);
                    }
                }
            }
        // } else {
        //     var view = this.viewContainer.createEmbeddedView(this.templateRef);
        //     let rootElem = view.rootNodes[0];

        //     if (rootElem) {
        //         this._renderer.setElementClass(rootElem, 'setupReadOnly', true);
        //     }
      //  }
    }
}

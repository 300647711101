import { Injectable } from '@angular/core';
import {Router, CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot,CanActivateChild,NavigationExtras } from '@angular/router';
import { Feature } from '../shared/globals';
import { SessionService } from './services/session.service';

@Injectable()
export class RolesGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,private sessionService:SessionService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var url: string = state.url;
        var userFeaturePermissions: any;
        userFeaturePermissions = JSON.parse(this.sessionService.getItem('userPermissions'));
        //Routes Gaurded -> Study Setup -- Study Setup Version -- Study Setup Details
        if ((url.indexOf('home/studysetup') >=0) || (url.indexOf('home/studysetupversion') >=0) || (url.indexOf('home/details') >=0)) {
            var readPermission = userFeaturePermissions.filter(x => x.FeatureId.toUpperCase() === Feature.StudySetup)[0].ReadAccess;
             if (readPermission) {
                return true;
            } else {
                this.router.navigate(['']);
                return false;
            }
        } 
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
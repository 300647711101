import * as productCollection from '../actions/collection.actions';
import {CartState} from '../ecommportal.state';

const initialstate: CartState = {

    cartitems: []
}

//reducer with empty initial state 
export function reducer(state = initialstate, action: productCollection.Actions): CartState {

    switch (action.type) {
        case productCollection.Add_Product_Success:
            {
            
                const cartItem = action.payload;
                const key = cartItem.key;

                var item = state.cartitems.findIndex(i => i.key == cartItem.key);
                if (item >= 0) {

                    state.cartitems[item].quantity = parseInt(cartItem.quantity.toString());
                    state.cartitems[item].price = parseInt(cartItem.quantity.toString()) *cartItem.product.price;
                    return state;

                }

                return Object.assign({}, state, {
                    cartitems: [...state.cartitems, cartItem]
                })

            }
        case productCollection.Load_Success:
            {

                const items = action.payload;
                return {

                    cartitems: items
                };

            }
        case productCollection.Remove_Product_Success:
            {

                const product = action.payload;

                return Object.assign({}, state, {

                    cartitems: state.cartitems.filter(p => p.key != product.key)
                });

            }

        case productCollection.clear_State:
            {

                return Object.assign({}, state, {

                    cartitems: []
                });

            }

        default:
            {
                return state;
            }

    }

}
import { Directive, Input, TemplateRef, ViewContainerRef, Renderer } from '@angular/core';
import{ QuotationStatus, RoleList } from '../globals'
import { SessionService } from "../services/session.service";
@Directive({
    selector: '[quotationReadOnly]'
})

export class QuotationReadOnlyDirective {
    @Input('quotationReadOnly') quotationStatusId: string;
    constructor(private templateRef: TemplateRef<any>,
        private _renderer: Renderer,
        private sessionService: SessionService,
        private viewContainer: ViewContainerRef) {
    }
    ngOnInit() {
        this.setStatusBasedView();
    }

    setStatusBasedView() {
        var roleId = this.sessionService.getItem('roleId');
        if (this.quotationStatusId == QuotationStatus.Draft && roleId == RoleList.ProposalDevelopment)
        {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            var view = this.viewContainer.createEmbeddedView(this.templateRef);
            let rootElem = view.rootNodes[0];
            
            if(rootElem) {
                this._renderer.setElementClass(rootElem, 'setupReadOnly', true);
            }
        }
    }
}

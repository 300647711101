import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[logicalNumbersOnly]'
})
export class LogicalOperatorNumberDirective {
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Shift', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete',
    'Control', 'c', 'v', 'x'];

  private specialLogicalKeys: Array<string> = ['<', '>', '-'];
  constructor(private el: ElementRef) {
  }

  private regex: RegExp = new RegExp(/^([+-<>]?[=]?[-]?\d*\.?\d{0,9})$/);
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // Allow Backspace, tab, end, cut, copy, paste and home keys 
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    let next: string = '';
      next = [current.slice(0, position), event.key == '.' ? '.' : event.key, current.slice(position)].join('');

    if (this.specialLogicalKeys.indexOf(event.key) !== -1) {
      if (position == 0) {
        return;
      }
      else if(event.key == '-')
      {
        if(position == 1 || (position == 2 && next[1]== '=') )
        {
          return;
        }
      }
      else {
        event.preventDefault();
        return;
      }
    }
    else if(event.key == '=' && position == 1)
    {
      if(next[0] == '-')
      {
        event.preventDefault();
        return;
      }else{
        return;
      }
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
import {Action} from '@ngrx/store';

import { OrderConfirmModel } from '../OrderConfirmation/Model/orderconfirm.model';


export const Clear_OrderState = 'Clear_OrderState';
export const UpdateOrder_State= 'UpdateOrder_State';
export const  SaveOrderState_ToLocal = 'SaveOrderState_ToLocal';
export const  LoadOrder_Action = 'LoadOrder_Action';
export const LoadSuccess_Action = 'LoadSuccess_Action';




export class updateOrderState implements Action {
    readonly type = UpdateOrder_State;

    constructor(public payload:OrderConfirmModel) {
              
    }
}

export class LoadOrderAction implements Action {
    readonly type = LoadOrder_Action;
    constructor(public payload: string) {

    }
}

export class LoadSuccessAction implements Action {
    readonly type = LoadSuccess_Action;
    constructor(public payload: OrderConfirmModel) {

    }
}

export class saveOrderStateToLocal implements Action {
    readonly type = SaveOrderState_ToLocal;

    constructor(public payload:OrderConfirmModel) {
        
    }
}

export class clearOrderState implements Action {
    readonly type = Clear_OrderState;

    constructor() {

    }
}



export type Actions= 

|saveOrderStateToLocal

|clearOrderState
|updateOrderState
|LoadOrderAction
|LoadSuccessAction


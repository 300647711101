import { Directive, Input, ElementRef, OnChanges, ChangeDetectorRef } from '@angular/core';
@Directive({
    selector: '[focus]'
})
export class FocusDirective implements  OnChanges {
    @Input() focus: boolean;

    constructor(private element: ElementRef,
                private changeDetectorRef: ChangeDetectorRef) {}


    ngOnChanges() {
         if (this.focus ) {
            this.element.nativeElement.focus();
            // workaround for "Expression changed after it was checked" error
            // force angular to run change detection after setting the focus on the element
            this.changeDetectorRef.detectChanges();
        }
    }
}

﻿import { SiteAddresses } from "../iScala/OnlineKitOrders/Model/onlinekitorders.model";

export class Utils {
    // Checks if the enteres string has no special characters and contains only alpha numeric values.
    static isStringAlphaNumeric(val: string): boolean {
        var alphaNumericRegEx: RegExp = new RegExp('^[A-Za-z0-9_@-]*$');
        if (!this.isStringNullOrEmpty(val)) {
            var isAlphaNumeric = alphaNumericRegEx.test(val);
            return isAlphaNumeric;
        }

        return true;
    };

    // Checks if the string is null or empty.
    static isStringNullOrEmpty(val: string): boolean {
        return (val.length === 0 || !val.trim());
    };    
   
    public static isGuid(value) {
        var isGuidregx = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return (!Utils.isEmpty(value)) && (isGuidregx.test(value));
    };
    public static isEmpty(obj: any) {
        if (obj == undefined
            || obj == null) {
            return true;
        }
        return false;
    };

    public static createGuid(){  
        function S4() {  
           return (((1+Math.random())*0x10000)|0).toString(16).substring(1);  
        }  
        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();  
     }  

     public static formatAddress(address: SiteAddresses | null): string {

        if(!address) {
            return '';
        }
        let addressParts = `${address.addressLine1}`;
    
          if (address.addressLine2) {
            addressParts += ` ${address.addressLine2}`;
          }
          if (address.addressLine3) {
            addressParts += ` ${address.addressLine3},`;
          } else {
            addressParts += ',';
          }
    
          if (address.street) {
            addressParts += ` ${address.street},`;
          }
    
          const cityStateZip = `${address.city}, ${
            address.state ? address.state + ' ' : ''
          }${address.zipCode}`;
    
          let countryPart = '';
          if (address.countryName) {
            countryPart = `, ${address.countryName}`;
          }
          return `${addressParts} ${cityStateZip}${countryPart}`
            .replace(/\s+,/g, ',')
            .replace(/,\s+/g, ', ')
            .trim();
      }
}
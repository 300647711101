import {Injectable} from '@angular/core';
import { Effect, Actions,ofType } from '@ngrx/effects';
import { Observable, defer, observable } from 'rxjs';
import {Database, DBModule} from '@ngrx/db';
import { Action } from '@ngrx/store';
import { of } from 'rxjs/observable/of';
import * as collection from '../actions/collection.actions';
import * as updatestudycollection from '../actions/updateStudySite.actions';
import { map, mergeMap, switchMap, merge, catchError,filter} from 'rxjs/operators';
import { SessionService } from '../../../app/shared/services/session.service'
import { CartItem } from '../ProductDetails/Model/productdetails.model';
import 'rxjs/operator/filter';
import { ProductOrderFilter } from '../ProductOrder/Model/productorderfilter.model';


@Injectable()
export class CollectionEffects {

    constructor(private actions$: Actions,
        private db: Database,
        private sessionService: SessionService) {}

    //open database on load
    @Effect({
        dispatch: false
    })
    openDB$: Observable < any > = defer(() => {

        return this.db.open('Product_dbv1', 1);

    });

    //update selected study site details to store and session
    @Effect()
    updateStudySite$ = this.actions$.pipe(
        ofType(updatestudycollection.update_StudySite),

        map((action: updatestudycollection.UpdateStudySite) => {

                const item: ProductOrderFilter = action.payload;
             

                this.sessionService.setItem('studySiteKey', JSON.stringify(item))
                return action.payload;
            }

        ),
        map((item: ProductOrderFilter) => new updatestudycollection.UpdateStudySiteSuccesss(item)),
        catchError(error => this.handleError(error))
    );

    //Load study site details from session    
    @Effect()
    LoadStudySite$ = this.actions$.pipe(
        ofType(updatestudycollection.Load_Site),

        map((action: updatestudycollection.LoadSite) => {

                return JSON.parse(this.sessionService.getItem('studySiteKey'));
            }

        ),
        map((item: ProductOrderFilter) => new updatestudycollection.UpdateStudySiteSuccesss(item)),
        catchError(error => this.handleError(error))
    );

    //Remove db itmes on logout
    @Effect()
    cleardb$ = this.actions$.pipe(
        ofType(collection.Remove_DB_items),
        map((action: collection.RemoveDbItems) => action.payload),
        mergeMap((item: any[]) =>
            this.db.executeWrite('cartitems', 'delete', item).map(() => new collection.ClearState())
        ), catchError(error => this.handleError(error))
    );

      //Remove db itmes on logout
      @Effect()
      removeItemfromDb$ = this.actions$.pipe(
          ofType(collection.Remove_Product),
          map((action: collection.RemoveProductAction) => action.payload),
          mergeMap((item: CartItem) =>
              this.db.executeWrite('cartitems', 'delete', [item.key]).map(() => new collection.RemoveProductSuccessAction(item))
          ), catchError(error => this.handleError(error))
      );


    //Add product to db and store
    @Effect()
    addProductToCollection$ = this.actions$.pipe(
        ofType(collection.Add_Product),
        map((action: collection.AddProductAction) => action.payload),
        mergeMap(Item =>
            this.db.insert('cartitems', [Item])
            .map(() =>

                new collection.AddProductSuccessAction(Item))
            .catch(() =>

                of (new collection.AddProductSuccessAction(Item)))
        ), catchError(error => this.handleError(error)));
        

    //Load cart items from db
    @Effect()
    loadCollection$ = this.actions$.pipe(
        ofType(collection.Load),
        map((action: collection.LoadAction) => action.payload),
        switchMap(item =>
            this.db.query('cartitems').pipe(filter((i: CartItem) => i.key.includes(item)))
            .toArray()
            .map((cartitems: CartItem[]) => new collection.LoadSuccessAction(cartitems))
            .catch(error => this.handleError(error))
        ));

    private handleError(error: any): any {
        let errorMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errorMsg);
        Observable.throw(errorMsg);
    }
}
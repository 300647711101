﻿import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
@Injectable()
export class ConfigService {
    private config: Object;
    private env: any;
    private result: Object;
    constructor(private http: Http) {
    }

    public load() {
        var baseUrl = location.protocol + "//" + location.host + location.pathname;
        return new Promise((resolve, reject) => {

                  this.http.get(baseUrl+'/assets/config/env.json')
                  .map(res => res.json())
                  .subscribe((envData) => {
                      this.env = envData;
                      this.http.get(baseUrl+'/assets/config/' + this.env.env + '.json?nocache=' + (new Date()).getTime())
                          .map(res => res.json())
                          .catch((error: any) => {
                              console.error(error);
                              return Observable.throw(error.json().error || 'Server error during loading of config file');
                          }).subscribe((data) => {
                              this.config = data;
                              this.http.get(baseUrl+'/assets/config/uiConfiguration.json')
                                  .map(res => res.json())
                                  .subscribe((uiData) => {
                                      this.result = uiData;
                                      resolve(true);
                                  });
                          });
                  });
        });

    }

    getEnv(key: any) {
        return this.env[key];
    }
    get(key: any) {
        return this.config[key];
    }
    public getUiConfiguration(key: any) {
        return this.result[key];
    }
};
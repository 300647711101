﻿import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class BlockUIService {

    public blockUIEvent: EventEmitter<any>;
    private requests: number[] = [];

    constructor() {
        this.blockUIEvent = new EventEmitter();
    }

    public startBlock() {
        this.requests.push(1);
        this.blockUIEvent.emit({
            value: this.requests.length > 0
        });
    }

    public stopBlock() {
        this.requests.pop();
        this.blockUIEvent.emit({
            value: this.requests.length > 0
        });
    }
}
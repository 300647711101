import * as collection from '../actions/updateStudySite.actions'
import {SiteState} from '../ecommportal.state';


const initialstate:SiteState = {

   
    selectedStudyId:"",
    selectedSiteId:"",
    studyNumber :"",
    siteCode : "",
    ecommShowPrice : false
     
    }

export function reducer (state=initialstate,action:collection.Actions):SiteState
{
switch(action.type)
{

    case collection.update_StudySite_Success:
    {var updatestate = initialstate;
       const productfilter = action.payload;
     
     if(productfilter != null){
        updatestate= Object.assign({},state,{
           selectedStudyId: productfilter.selectedStudyId,
           selectedSiteId : productfilter.selectedSiteId,
           studyNumber : productfilter.studyNumber,
           siteCode :productfilter.siteCode,
           ecommShowPrice:productfilter.ecommShowPrice
           
           

    })} ;
   
    return state=updatestate;


}

case collection.update_StudySite:

case collection.Clear_SiteState:{

    return state=initialstate;
}

default:
{
    return state;
}

}
}
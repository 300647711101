﻿import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from '@angular/forms'

export function emailValidator(): ValidatorFn {
    // (\w)\1{3,} -- This is for testing the repeated characters
    // (abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+ -- This is for 3 consecutive numbers

    return (control: AbstractControl): { [key: string]: any } => {

        var isValid: boolean = true;
        
        var validEmailRegEx: RegExp = new RegExp("/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i", 'g');

        const enteredValue = control.value;
        if (enteredValue != null && enteredValue.trim() !== "") {
        
            var isValid = validEmailRegEx.test(enteredValue);

            
            return isValid ? { 'validEmail': { name } } : null;
        }

        return isValid ? { 'validEmail': { name } } : null;        
    };
}

@Directive({
    selector: '[validEmail]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }]
})
export class EmailValidatorDirective implements Validator, OnChanges {
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['validEmail'];
        if (change) {
            const val: string | RegExp = change.currentValue;
            const re = val instanceof RegExp ? val : new RegExp(val, 'i');
            this.valFn = emailValidator();
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// var currentUrl = document.URL;
//  if (sessionStorage.getItem('uname') == null && sessionStorage.getItem('upwd') == null) {
//   if(document.URL.indexOf("forgotpassword") > -1 ||
//     document.URL.indexOf("changepassword") > -1 ||
//     document.URL.indexOf("register") > -1||
//     document.URL.indexOf("resetpassword") > -1){
//     //Do nothing - will follow route
//   }
//   else {
//       var redirectUrl;
//        if(document.URL.endsWith("/#/")){
//           redirectUrl = document.URL.replace("/#/", "/login.html");
//         } else
//         if (document.URL.endsWith("/")) {
//           redirectUrl = document.URL + "login.html";
//         }
//        else 
//         {
//           redirectUrl = currentUrl;
//         }
//         window.location.href = redirectUrl;
//   }
 
//  }

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
  

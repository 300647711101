import {CartState,SiteState,OrderConfirmState} from '../ecommportal.state';
import {AppState} from '../ecommportal.reducer'
import { createSelector } from 'reselect';
import { CartItem } from '../ProductDetails/Model/productdetails.model';


const getSiteState = (state: AppState) :SiteState=> state.site //returns selected state and site
const getCheckoutState = (state: AppState) :CartState=> state.checkout //return cart items
const getOrderState = (state:AppState):OrderConfirmState=>state.order

//get cart items
const fetchCartItems = (state: CartState): CartItem[] => {
    return state.cartitems as CartItem[];
  };

  //get study details
const fetchStudySiteCode = (state:SiteState)=>{
  return state ;
}

//get oder details 
const fetchOrderDetails = (state:OrderConfirmState)=>{
  return state ;
}

  export const getCartItems = createSelector(
    getCheckoutState,
    fetchCartItems
  );


  export const getstudysitedetails = createSelector(
    getSiteState,
    fetchStudySiteCode

  );

  export const getorderdetails = createSelector(
    getOrderState,
    fetchOrderDetails

  );
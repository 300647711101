import { Directive, ElementRef, Renderer, Input } from '@angular/core';

@Directive({
    selector: '[setelementautofocus]'
})
export class SetAutofocusDirective
{
    private _autofocus;
    constructor(private el: ElementRef, private renderer: Renderer)
    {
    }

    ngOnInit()
    {
    }

    ngAfterViewInit()
    {
        if (this._autofocus || typeof this._autofocus === "undefined")
            this.renderer.invokeElementMethod(this.el.nativeElement, 'focus', []);
    }

    @Input() set setelementautofocus(condition: boolean)
    {
        this._autofocus = condition != false;
    }
}
import {Injectable} from '@angular/core';
import { Effect, Actions,ofType } from '@ngrx/effects';
import { Observable, defer, observable } from 'rxjs';
import {Database, DBModule} from '@ngrx/db';
import * as collection from '../actions/order.actions';
import { map, mergeMap, switchMap, merge, catchError,filter} from 'rxjs/operators';
import { SessionService } from '../../../app/shared/services/session.service'
import 'rxjs/operator/filter';
import { OrderConfirmModel } from '../OrderConfirmation/Model/orderconfirm.model';


@Injectable()
export class OrderEffects {

    constructor(private actions$: Actions,
        private db: Database,
        private sessionService: SessionService) {}

        @Effect()
        saveOrderStateToLocal$ = this.actions$.pipe(
            ofType(collection.SaveOrderState_ToLocal),
            map((action: collection.saveOrderStateToLocal) => action.payload),
            mergeMap((Item:OrderConfirmModel) =>
                this.db.insert('orders', [Item])
                .map(() =>
                       
                 new collection.updateOrderState(Item)
                   )
                
            ), catchError(error => this.handleError(error)));

            @Effect()
            loadCollection$ = this.actions$.pipe(
                ofType(collection.LoadOrder_Action),
                map((action: collection.LoadOrderAction) => action.payload),
                switchMap(item =>
                    this.db.query('orders').pipe(filter((i: OrderConfirmModel) => i.orderKey.includes(item)))
                    
                    .map((order: OrderConfirmModel) => new collection.LoadSuccessAction(order))
                    .catch(error => this.handleError(error))
                ));

                
     

            private handleError(error: any): any {
                let errorMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                console.error(errorMsg);
                Observable.throw(errorMsg);
            }

    
}
﻿import {Injectable, ApplicationRef, ComponentFactoryResolver,NgZone} from '@angular/core';
import {ToastrService } from 'ngx-toastr';

import * as Globals from "../../shared/globals";

@Injectable()
export class NotificationService {
    constructor(private toastr: ToastrService, private appl: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver) {

    }

    showWarning(message: string, title: string) {
        let options = {
            positionClass: 'toast-top-right',
            showCloseButton: true,           
            dismiss: 'click',
            extendedTimeOut:2000
        }
        let ngZone = new NgZone({enableLongStackTrace:true});        
        this.toastr.warning(message, title, options);
    }

    showWarningWithHtml(message: string, title: string) {
        let options = {
            positionClass: 'toast-center-center',
            showCloseButton: true,           
            dismiss: 'click',
            extendedTimeOut:1000
        }
        let ngZone = new NgZone({enableLongStackTrace:true});
        this.toastr.warning(message, title, options);       
    }

    showError(message: string, title: string) {
        let options = {
            positionClass: 'toast-top-right',
            showCloseButton: true,           
            dismiss: 'click',
            extendedTimeOut:1000
        }
        let ngZone = new NgZone({enableLongStackTrace:true});
        this.toastr.error(message, title, options);
    }

    showSuccess(message: string, title: string) {
        let options = {
            positionClass: 'toast-top-right',
            showCloseButton: true,           
            dismiss: 'click',
            extendedTimeOut:1000
        }
        let ngZone = new NgZone({enableLongStackTrace:true});
        this.toastr.success(message, title, options);
    }

    showInfo(message: string, title: string) {
        let options = {
            positionClass: 'toast-top-right',
            showCloseButton: true,         
            progressAnimate:'decreasing'  ,
            dismiss: 'click',
            extendedTimeOut:1000
        }
        let ngZone = new NgZone({enableLongStackTrace:true});
        this.toastr.info(message, title, options);
    }

    clearAllToasts() {
        this.toastr.clear();
    }
}
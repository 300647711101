import { Directive, Input, TemplateRef, ViewContainerRef, Renderer } from '@angular/core';
import{ InvoiceStatus, RoleList } from '../globals'
import { SessionService } from "../services/session.service";
@Directive({
    selector: '[invoiceReadOnly]'
})

export class InvoiceReadOnlyDirective {
    @Input('invoiceReadOnly') invoiceStatusId: string;
    constructor(private templateRef: TemplateRef<any>,
        private _renderer: Renderer,
        private sessionService: SessionService,
        private viewContainer: ViewContainerRef) {
    }
    ngOnInit() {
        this.setStatusBasedView();
    }

    setStatusBasedView() {
        var roleId = this.sessionService.getItem('roleId');
        if (this.invoiceStatusId.toUpperCase() == InvoiceStatus.Draft)
        {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            var view = this.viewContainer.createEmbeddedView(this.templateRef);
            let rootElem = view.rootNodes[0];
            
            if(rootElem) {
                this._renderer.setElementClass(rootElem, 'setupReadOnly', true);
            }
        }
    }
}

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WritePermissionDirective } from '../shared/directives/writepermission.directive';
import { ReadPermissionDirective } from '../shared/directives/readpermission.directive';
import { AuditReasonPopupDirective } from "../shared/directives/auditreasonpopup.directive";
import { SetUpVersionReadOnlyDirective } from '../shared/directives/setupversionreadonly.directive';
import { QuotationReadOnlyDirective } from '../shared/directives/quotationreadonly.directive';
import { InvoiceReadOnlyDirective } from '../shared/directives/invoicereadonly.directive';
import { AddCommentReadOnlyDirective } from '../shared/directives/addcommentreadonly.directive';
// import {OnlySpacesNotAllowedDirective} from './validators/onlyspacesnotallowedValidator';
import { ShowAndHideDirective } from '../shared/directives/showhide.directive';
import { SetAutofocusDirective } from './directives/setautofocus.directive';
import { TruncatePipe } from '../shared/pipes/truncate.pipe';
import { ShortDatePipe } from '../shared/pipes/shortdate.pipe';
import { ConfirmDialogModule } from 'primeng/primeng';
import { FetchBaseComponent, UnSavedChangesConfirmDialogGuard } from './BaseComponent/fetchbase.component';
import { ForbiddenNameValidatorDirective } from './validators/forbiddenNameValidator';
import { RepeatedCharactersValidatorDirective } from './validators/repeatedCharactersValidator';
import { EmailValidatorDirective } from './validators/email.validator';
import { FocusDirective } from './focus.directive';
import { NavDropdownDirective, NavDropdownToggleDirective } from './nav-dropdown.directive';
import { SidebarToggleDirective, MobileSidebarToggleDirective, SidebarOffCanvasCloseDirective } from './sidebar.directive';
import { ElementFocusDirective } from './directives/focushighlight.directive';
import { OnlySpacesNotAllowedDirective } from './validators/onlyspacesnotallowedValidator';
import { RegExValidatorDirective } from './validators/regExValidator';
import { PageHeaderComponent } from '../EComPortal/shared/PageHeader/page-header.component';
import { OnlyNumbersDirective } from '../shared/directives/numbersonly.directive';
import { TwoDigitDecimaNumberDirective } from '../shared/directives/twodigitdecimanumber.directive';
import { FourDigitDecimaNumberDirective } from '../shared/directives/fourdigitdecimanumber.directive';
import { OnlyNumbersDirectiveForReferenceValue } from '../shared/directives/numbersonlyForReferenceValue.directive';
import { TrimDirective } from './directives/valuetrim.directive';
import { AddRowDirective } from './directives/addrow.directive';
import { BlockCopyPasteDirective } from './directives/blockCopyPasteDirective.directive';
import { DisableElementDirective } from './directives/disableelement.directive';
import { LogicalOperatorNumberDirective } from './directives/logicalOperatorNumbers.directive';
import { FormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NumberswithoutdecimalDirective } from './directives/numberswithoutdecimal.directive';
import { NineDigitDecimalNumberDirective } from './directives/nine-digit-decimal-number.directive';
import { DynamicArrayFilter } from './pipes/dynamic-array-filter.pipe';

@NgModule({
    imports: [CommonModule, RouterModule, ConfirmDialogModule],
    providers: [UnSavedChangesConfirmDialogGuard],
    declarations: [FetchBaseComponent, TruncatePipe, WritePermissionDirective, ReadPermissionDirective, AuditReasonPopupDirective, SetUpVersionReadOnlyDirective, SetAutofocusDirective, ShowAndHideDirective, ShortDatePipe, QuotationReadOnlyDirective, InvoiceReadOnlyDirective, AddCommentReadOnlyDirective
        , ForbiddenNameValidatorDirective, RepeatedCharactersValidatorDirective, EmailValidatorDirective, FocusDirective, NavDropdownDirective,
        NavDropdownToggleDirective, SidebarToggleDirective, MobileSidebarToggleDirective, SidebarOffCanvasCloseDirective, ElementFocusDirective, OnlySpacesNotAllowedDirective, RegExValidatorDirective, PageHeaderComponent,
        OnlyNumbersDirective, TwoDigitDecimaNumberDirective, FourDigitDecimaNumberDirective, OnlyNumbersDirectiveForReferenceValue, 
        TrimDirective, AddRowDirective, BlockCopyPasteDirective, DisableElementDirective, LogicalOperatorNumberDirective, NumberswithoutdecimalDirective, NineDigitDecimalNumberDirective, DynamicArrayFilter
    ],
    exports: [FetchBaseComponent, TruncatePipe, CommonModule, FormsModule, RouterModule, WritePermissionDirective, ReadPermissionDirective, AuditReasonPopupDirective, SetUpVersionReadOnlyDirective, SetAutofocusDirective, ShowAndHideDirective, ShortDatePipe, QuotationReadOnlyDirective, InvoiceReadOnlyDirective, AddCommentReadOnlyDirective, PageHeaderComponent,
        OnlyNumbersDirective, TwoDigitDecimaNumberDirective, FourDigitDecimaNumberDirective, OnlyNumbersDirectiveForReferenceValue, 
        TrimDirective, AddRowDirective, BlockCopyPasteDirective, DisableElementDirective, LogicalOperatorNumberDirective, NumberswithoutdecimalDirective, NineDigitDecimalNumberDirective, DynamicArrayFilter]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}

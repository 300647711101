import { Directive, ElementRef, Renderer, Input,ViewContainerRef,TemplateRef } from '@angular/core';
import { SessionService } from '../services/session.service';

@Directive({
    selector: '[showandhideElement]'
})
export class ShowAndHideDirective
{
    private isInternal:boolean=false;
    constructor( private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,private sessionService:SessionService)
    {
        
    }

    ngOnInit()
    {
        var isExternal=this.sessionService.getItem('isExternal');
        if(isExternal)
        this.isInternal= isExternal ==="True" ? false:true;
    }

    ngAfterViewInit()
    {
          if(this.isInternal && this.isInternal===true){
              this.viewContainer.createEmbeddedView(this.templateRef);
          }else{
              this.viewContainer.clear();
          }
           //this.el.nativeElement.style.visibility=this.isInternal?'visible':'hidden';
    }

}
﻿import { Component, Directive, Input, ElementRef} from '@angular/core';
@Directive({
    selector: 'imsElementfocus'
})
export class ElementFocusDirective {
    @Input('imsElementfocus')
    focus: boolean;
    constructor( private element: ElementRef) { }
    protected ngOnChanges() {
        this.element.nativeElement.focus();
    }
}
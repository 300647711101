import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNineDigitDecimalNumber]'
})
export class NineDigitDecimalNumberDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete',
                'Control','c','v','x'];
  constructor(private el: ElementRef) {
  }
  
  private regex: RegExp = new RegExp(/^(-?\d+|\d{1,3}(,\d{3})*)(\.\d{0,9})?$/g);
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, cut, copy, paste and home keys 
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    let next: string ='';
   
    next = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');

    if (event.key == '-') {
      if (position == 0) {
        return;
      }
      else {
        event.preventDefault();
        return;
      }
    }

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

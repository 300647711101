import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[numberswithoutdecimal]'
})
export class NumberswithoutdecimalDirective {

  constructor(private el: ElementRef) { }

  @Input() allowMultiLine: boolean = false;
  @Input() allowNegative: boolean = false;
  @Input() allowDecimal: boolean = false;
  @Input() maxLength: number = 0;
  @Input() isNotFirstValueZero:boolean=false
  regex: RegExp;

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.validate(event, event.key === 'Enter' ? '\n' : event.key);
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;
    if(event.key === '0' && inputValue.length === 0 && this.isNotFirstValueZero) {
      event.preventDefault();
    }
  }

  @HostListener('cut', ['$event'])
  onCut(event: Event) {
    this.validate(event, '');
  }

  @HostListener('paste', ['$event'])
 onPaste(event: ClipboardEvent) {
   const pastedText = event.clipboardData.getData('text');
   this.validate(event, pastedText);
 }

  validate(event: Event, text: string) {
    const txtInput = this.el.nativeElement;
    let newValue = (txtInput.value.substring(0, txtInput.selectionStart)
      + text + txtInput.value.substring(txtInput.selectionEnd));
    if (newValue == '-' && this.allowNegative) {
      newValue = '-0';
    }
    if (!this.regex) {
      this.regex = <RegExp>eval('/^'
        + (this.allowNegative ? '-?' : '')
        + '\\d*'
        + '$/g');
    }
    var lines = this.allowMultiLine ? newValue.split('\n') : [newValue];
    for (let line of lines) {
      let lineText = line.replace('\r', '');
      if ((this.maxLength && lineText.length > this.maxLength || !lineText.match(this.regex)) || lineText === "." || lineText.includes(".")) {
        event.preventDefault();
        return;
      }
      else 
      {
        var onlywholeNumber = lineText.toString().length;
        if(onlywholeNumber > 9)
          {
             event.preventDefault();
             return;
          } 
      }
    }
  }
}


﻿import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras } from '@angular/router';
import {LoginService} from './../Authentication/Login/Services/login.service';
import { UserRoles } from './globals';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private loginService: LoginService) {}
    public  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var isUserLoggedIn = this.loginService.isUserLoggedIn();
        if (isUserLoggedIn) {
            const allowedUserRoles = this.getRoutePermissions(route);
            return  this.checkPermission(allowedUserRoles,route);
            //return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

   public  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
       return this.canActivate(route, state);                                                                                
    }

     /**
   * Get allowed user roles from the route.
   * @param {ActivatedRouteSnapshot} route - The route.
   * @returns {string[]} All user roles that are allowed to access the route.
   */
  private getRoutePermissions(route: ActivatedRouteSnapshot): UserRoles[] {
    if (route.data && route.data.UserRoles) {
      return route.data.UserRoles as UserRoles[];
    }
    return null;
  }

  /**
   * Check if a user is authenticated
   * @param {string[]} allowedUserRoles - These user roles have the permissions to access the route.
   * @returns {Promise<boolean>} True if user is authenticated otherwise false
   */
   private checkPermission(allowedUserRoles: UserRoles[],route: ActivatedRouteSnapshot,): boolean {
       let isRoleAllowed:boolean=false;
       var loginuserRole = this.loginService.getLoginUserRole();
    if(loginuserRole.toUpperCase()==UserRoles.Administrator)  
    {
        return true;
    }
    if(allowedUserRoles==null || (allowedUserRoles!=null && allowedUserRoles.includes('All')))
    {
         return true;
    }
    if(this.loginService.isUserExternal()==true && allowedUserRoles.includes('Internal'))
    {
        return false;
    }
    else
    {
        
        allowedUserRoles.forEach(a=>
        {
            if(a==loginuserRole.toUpperCase())
            {
            isRoleAllowed= true;
            }
        });

    }
    
    if(!isRoleAllowed)
    {
    this.router.navigate(['']);
    return false;
    }
    else
    return true;
    
}
   
}
﻿import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from '@angular/forms'

export function repeatedCharactersValidator(): ValidatorFn {
    // (\w)\1{3,} -- This is for testing the repeated characters
    // (abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+ -- This is for 3 consecutive numbers

    return (control: AbstractControl): { [key: string]: any } => {

        var isValid: boolean = true;
        var repeatedCharRegEx: RegExp = new RegExp('(.)\\1{2,}', 'g');
        var consecutiveCharRegEx: RegExp = new RegExp('(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|012|123|234|345|456|567|678|789)+', 'g');

        const enteredValue = control.value;
        if (enteredValue != null && enteredValue.trim() !== "") {
            var isValid1 = repeatedCharRegEx.test(enteredValue);

            var isValid2 = consecutiveCharRegEx.test(enteredValue);

            isValid = isValid1 || isValid2;
            return isValid ? { 'repeatedCharacters': { name } } : null;
        }

        return enteredValue;
    };
}

@Directive({
    selector: '[repeatedCharacters]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RepeatedCharactersValidatorDirective, multi: true }]
})
export class RepeatedCharactersValidatorDirective implements Validator, OnChanges {
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['repeatedCharacters'];
        if (change) {
            const val: string | RegExp = change.currentValue;
            const re = val instanceof RegExp ? val : new RegExp(val, 'i');
            this.valFn = repeatedCharactersValidator();
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}
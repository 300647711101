import { Component, ViewContainerRef, OnInit ,ChangeDetectorRef} from '@angular/core';
import { ConfigService } from './config/ConfigService';
import { BlockUIService } from './shared/services/blockui.service';
import { RolesPermissionsService } from './shared/services/rolespermissions.service';
//import { Idle, DEFAULT_INTERRUPTSOURCES } from 'ng2-idle';
import { SessionService } from './shared/services/session.service';
import { NgRedux } from '@angular-redux/store';
import { IState, initialState, authenticationValidationReducer } from './Session/authenticationvalidation';
import { VersionCheckService } from './HashPolling/version-check.service';

@Component({
    selector: 'fetch-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    appVersion: string = "";
    copyrightYear: string = "";
    footerMessage: string = "";
    privacyPolicyLink = "";
    frequency: any;

    private viewContainerRef: ViewContainerRef;
    loading: Boolean;
    loadingFromProxy: boolean = false;
    public notificationsOptions = {
        position: ['bottom', 'right'],
        timeOut: 5000,
        preventDuplicates: true,
        preventLastDuplicates: 'visible'
    };
    display: boolean = false;

    constructor(viewContainerRef: ViewContainerRef, private blockUIService: BlockUIService, private configService: ConfigService,
        private sessionService: SessionService, session: NgRedux<IState>,
        private versionCheckService : VersionCheckService,
        private changeDetector: ChangeDetectorRef) {
        session.configureStore(authenticationValidationReducer, initialState);
    }
    ngOnInit() {
        var baseUrl = location.protocol + "//" + location.host + location.pathname;
        var url = baseUrl+'/assets/version.json';

        this.versionCheckService.initVersionCheck(url,false);

        this.blockUIService.blockUIEvent.subscribe(event => this.blockUnBlockUI(event));

        this.blockUIService.blockUIEvent.emit({
            value: false
        });
        this.appVersion = this.configService.get("AppVersion");
        this.copyrightYear = this.configService.get("copyrightYear");
        this.footerMessage = this.configService.get("footerMessage");
        this.privacyPolicyLink = this.configService.get('privacyPolicyLink');

        //this is added to accomodate proxy login page. We should not show this footer when the loading.
        if (this.sessionService.getItem('uname') != null && this.sessionService.getItem('upwd') != null) {
            this.loadingFromProxy = true;
        }
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
      }

    private blockUnBlockUI(event) {
        this.loading = event.value;
    }

    openPrivacyPolicyPage() {
        window.open(this.privacyPolicyLink);
    }

    openTermsandConditionPage() {
        this.display = true;
    }
    closeDialog() {
        this.display = false;

    }
}

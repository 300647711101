﻿import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {AuditTrialReasonService} from "../sharedservices/audittrialreason.service";
import {ConfirmationService} from 'primeng/primeng';
@Directive({
    selector: '[isAuditablePopup]',
    providers: [ConfirmationService]
})
export class AuditReasonPopupDirective {
    @Input() isAuditablePopup: boolean = false;
    @Input() callBackMethod=()=>{};
    constructor(private el: ElementRef, private auditTrialReasonService: AuditTrialReasonService, private confirmationService: ConfirmationService) { }

    @HostListener('click', ['$event']) onClick(event) {
        this.auditTrialReasonService.showpopEvent.emit({ value: this.isAuditablePopup });
        this.confirmationService.confirm({ message:"", accept: () => { this.callBackMethod()}});
    }
}
import { Injectable } from '@angular/core';
import { Http, Headers, Response, ResponseContentType } from '@angular/http';
import * as Globals from '../shared/globals';
import { ConfigService } from '../config/ConfigService';
import { BlockUIService } from '../shared/services/blockui.service';
import * as CryptoJS from '../crypto-js';
import * as AntiForgeryTokenConfig from '../shared/AntiForgeryTokenConfig';
import { Utils } from '../shared/utils';

import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import { SessionService } from './services/session.service';
@Injectable()
export class HttpClient {
    http: Http;
    baseUrl: string;
    ezMoneyBaseUrl: string;
    dataLakeBaseUrl: string;
    eComBaseUrl: string;
    transConnectBaseUrl: string;
    catalogueBaseUrl: string;
    sampleQueryMgmtBaseUrl: string;
    simsUrl:string
    key : any;  
    iv : any;
    constructor(http: Http, config: ConfigService, private blockUIService: BlockUIService, private sessionService: SessionService) {
        this.http = http;
        this.baseUrl = config.get('apiUrl');
        this.ezMoneyBaseUrl = config.get('ezMoneyApiUrl');
        this.sampleQueryMgmtBaseUrl = config.get('sampleQueryMgmtApiUrl')
        this.dataLakeBaseUrl = config.get('dataLakeApiUrl');
        this.eComBaseUrl = config.get('ecomApiUrl');
        this.transConnectBaseUrl = config.get('transConnectApiUrl');
        this.catalogueBaseUrl = config.get('catalogueApiUrl');
        this.simsUrl = config.get('simsUrl');
        this.key = CryptoJS.enc.Utf8.parse(config.get("passwordEncryptionKey"));
        this.iv = CryptoJS.enc.Utf8.parse(config.get("passwordEncryptionIV"));
    }
    callBlockUI(isBlockedUI: boolean) {
        //this.CheckForblockUI(isBlockedUI);
    }
    createAuthorizationHeader(headers: Headers) {
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');

        headers.append('Accept', 'application/json');
        //headers.append('Access-Control-Allow-Origin', 'Content-Type');
    }

    createAuthorizationHeaderWithoutSecretKey(headers: Headers) {
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');

        headers.append('Accept', 'application/json');
        //headers.append('Access-Control-Allow-Origin', 'Content-Type');
    }


    uploadFile(file: any, invoiceStudyId: any, UserId: any, uploadImgUrl: any, apiurl: any): Observable<any> {
        this.CheckForblockUI(true);
        var userId = this.sessionService.getItem('loggedInUserId');
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            let formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("invoiceStudyId", invoiceStudyId);
            formData.append("userId", UserId)
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', uploadImgUrl + apiurl, true);
            xhr.send(formData);
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    uploadFileByRecordReference(file: any, recordReferenceId: any, UserId: any, uploadImgUrl: any, apiurl: any): Observable<any> {
        this.CheckForblockUI(true);
        var userId = this.sessionService.getItem('loggedInUserId');
        var userName = Globals.authUser.userName;
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            let formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("recordReferenceId", recordReferenceId);
            formData.append("userId", UserId)
            formData.append("userName", userName)
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', uploadImgUrl + apiurl, true);
            xhr.send(formData);
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
    get(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeader(headers);
        return this.http.get(this.baseUrl + url,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    getWithResponseTypeBlob(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.get(this.baseUrl + url,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    postWithResponseTypeBlob(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.post(this.baseUrl + url,
            data,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    getWithNoBlockUI(url): Observable<any> {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.get(this.baseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }

    post(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.post(this.baseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    postWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeader(headers);
        return this.http.post(this.baseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    postWithXhr(url, data: FormData): Observable<any> {
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            let headers = new Headers();
            this.createAuthorizationHeader(headers);

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', this.sessionService.getItem('id_token'))
            xhr.send(data);
        });
    }

    postWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.post(this.baseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    handleResponse(response: Response): any {
        this.CheckForblockUI(false);
        return response;
    }
    handleResponseWithNoBlockUI(response: Response): any {
        return response;
    }

    handleResponseError(error: Response): any {
        console.log(error);
        this.CheckForblockUI(false);
        return Observable.throw(error || 'Server error');;
    }

    CheckForblockUI(blockUI: boolean) {
        if (blockUI) {
            this.blockUIService.startBlock();
        }
        else {
            this.blockUIService.stopBlock();
        }
    }
    /**
     * Default password required without passsing token
     */
    defaultPost(url, data): Observable<any> {
        let headers = new Headers();
        return this.http.post(this.baseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    defaultGet(url): Observable<any> {
        let headers = new Headers();
        headers.append('Expires', '0');
        return this.http.get(this.baseUrl + url,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

        
/////// eCom separate API /////////////

eComGet(url):Observable<any> {
    this.CheckForblockUI(true);
    let headers = new Headers();
    headers.append('Expires', '0');
    this.createAuthorizationHeader(headers);
    return this.http.get(this.eComBaseUrl + url,
    {
        headers: headers
    }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}

eComGetWithResponseTypeBlob(url):Observable<any> {
    this.CheckForblockUI(true);
    let headers = new Headers();
    headers.append('Authorization', this.sessionService.getItem('id_token'));
    headers.append('SecretKey', this.generateAntiForgeryToken());
    headers.append('Content-Type', 'application/json');        
    headers.append('Accept', 'application/json');
    headers.append('Expires', '0');        
    return this.http.get(this.eComBaseUrl + url,
    {
        responseType : ResponseContentType.Blob,
        headers:headers
    }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}

eComPostWithResponseTypeBlob(url,data):Observable<any> {
    this.CheckForblockUI(true);
    let headers = new Headers();
    headers.append('Authorization', this.sessionService.getItem('id_token'));
    headers.append('SecretKey', this.generateAntiForgeryToken());
    headers.append('Content-Type', 'application/json');        
    headers.append('Accept', 'application/json');
    headers.append('Expires', '0');        
    return this.http.post(this.eComBaseUrl + url,
    data,
    {
        responseType : ResponseContentType.Blob,
        headers:headers
    }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}


eComGetWithNoBlockUI(url): Observable<any>{
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.get(this.eComBaseUrl + url,
        {
            headers: headers
        })
        .map((response: any) => this.handleResponseWithNoBlockUI(response),
        (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
}


eComPost(url, data): Observable<any> {
    this.CheckForblockUI(true);
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.post(this.eComBaseUrl + url,
        data,
        {
            headers: headers
        }).map((response: any) => this.handleResponse(response),
        (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}

eComPostWithHeader(url, data, headers: any): Observable<any> {
    this.CheckForblockUI(true);
    this.createAuthorizationHeader(headers);
    return this.http.post(this.eComBaseUrl + url,
        data,
        {
            headers: headers
        }).map((response: any) => this.handleResponse(response),
        (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}

eComPostWithNoBlockUI(url, data): Observable<any> {
    this.CheckForblockUI(false);
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.post(this.eComBaseUrl + url,
        data,
        {
            headers: headers
        }).map((response: any) => this.handleResponseWithNoBlockUI(response),
        (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
}

///////////////// eCom //////////////////////////


    ezMoneyGet(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.ezMoneyBaseUrl + url,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    ezMoneyGetWithResponseTypeBlob(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        //headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.get(this.ezMoneyBaseUrl + url,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    ezMoneyPostWithResponseTypeBlob(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        //headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.post(this.ezMoneyBaseUrl + url,
            data,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    ezMoneyGetWithNoBlockUI(url): Observable<any> {
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.ezMoneyBaseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }

    ezMoneyPost(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.ezMoneyBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    ezMoneyPostWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.ezMoneyBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    ezMoneyPostWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.ezMoneyBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    generateAntiForgeryToken() {
        let currentTimeStamp = new Date().getTime().toString();
        let bearertoken = this.sessionService.getItem('id_token');
        const userId =this.sessionService.getItem('loggedInUserId');
        let newguid = Utils.createGuid();
        
        let encryptedHash = CryptoJS.HmacSHA256(AntiForgeryTokenConfig.AntiForgeryTokenProperies.Token + bearertoken + newguid + currentTimeStamp, (bearertoken + newguid + currentTimeStamp));
        let encryptedKey = CryptoJS.enc.Base64.stringify(encryptedHash);

        var secretKey = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(encryptedKey + AntiForgeryTokenConfig.AntiForgeryTokenProperies.Separator + (bearertoken + newguid + currentTimeStamp) 
        + AntiForgeryTokenConfig.AntiForgeryTokenProperies.Separator + currentTimeStamp
        + AntiForgeryTokenConfig.AntiForgeryTokenProperies.Separator + bearertoken
        + AntiForgeryTokenConfig.AntiForgeryTokenProperies.Separator + userId),
                 this.key,  
                {  
                    keySize: 128 / 8,  
                    iv: this.iv,  
                    mode: CryptoJS.mode.CBC,  
                    padding: CryptoJS.pad.Pkcs7  
                }); 

        return secretKey;
    }

    dataLakeGet(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.dataLakeBaseUrl + url,
        {
            headers: headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    dataLakeGetWithResponseTypeBlob(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.get(this.dataLakeBaseUrl + url,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    dataLakePostWithResponseTypeBlob(url,data):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.post(this.dataLakeBaseUrl + url,
        data,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    dataLakeGetWithNoBlockUI(url): Observable<any>{
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.dataLakeBaseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }
    
    dataLakePost(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.dataLakeBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
   
    dataLakeWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.dataLakeBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    dataLakePostWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.dataLakeBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    transConnectGet(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.transConnectBaseUrl + url,
        {
            headers: headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    transConnectGetWithResponseTypeBlob(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.get(this.transConnectBaseUrl + url,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    transConnectPostWithResponseTypeBlob(url,data):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.post(this.transConnectBaseUrl + url,
        data,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    transConnectGetWithNoBlockUI(url): Observable<any>{
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.transConnectBaseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }
    
    transConnectPost(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.transConnectBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
   
    transConnectPostWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.transConnectBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    transConnectPostWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.transConnectBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    catalogueGet(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.catalogueBaseUrl + url,
        {
            headers: headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    catalogueGetWithResponseTypeBlob(url):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.get(this.catalogueBaseUrl + url,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    cataloguePostWithResponseTypeBlob(url,data):Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('Content-Type', 'application/json');        
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');        
        return this.http.post(this.catalogueBaseUrl + url,
        data,
        {
            responseType : ResponseContentType.Blob,
            headers:headers
        }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    catalogueGetWithNoBlockUI(url): Observable<any>{
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.catalogueBaseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }
    
    cataloguePost(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.catalogueBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
   
    cataloguePostWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.catalogueBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    cataloguePostWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.catalogueBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
            (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    //////////////////////////SampleQueryMgmt//////////////
    sampleQueryMgmtGet(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Expires', '0');
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.sampleQueryMgmtBaseUrl + url,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    sampleQueryMgmtGetWithResponseTypeBlob(url): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.get(this.sampleQueryMgmtBaseUrl + url,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    sampleQueryMgmtPostWithResponseTypeBlob(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        headers.append('Authorization', this.sessionService.getItem('id_token'));
        headers.append('SecretKey', this.generateAntiForgeryToken());
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Expires', '0');
        return this.http.post(this.sampleQueryMgmtBaseUrl + url,
            data,
            {
                responseType: ResponseContentType.Blob,
                headers: headers
            }).map((response: any) => this.handleResponse(response), (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    sampleQueryMgmtGetWithNoBlockUI(url): Observable<any> {
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.get(this.sampleQueryMgmtBaseUrl + url,
            {
                headers: headers
            })
            .map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
        //.subscribe((response) => this.handleResponse(response, false), (error) => this.handleResponseError(error, false));
    }
    sampleQueryMgmtPost(url, data): Observable<any> {
        this.CheckForblockUI(true);
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        
        return this.http.post(this.sampleQueryMgmtBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
    sampleQueryMgmtPostWithHeader(url, data, headers: any): Observable<any> {
        this.CheckForblockUI(true);
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.sampleQueryMgmtBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponse(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }

    sampleQueryMgmtPostWithNoBlockUI(url, data): Observable<any> {
        this.CheckForblockUI(false);
        let headers = new Headers();
        this.createAuthorizationHeaderWithoutSecretKey(headers);
        return this.http.post(this.sampleQueryMgmtBaseUrl + url,
            data,
            {
                headers: headers
            }).map((response: any) => this.handleResponseWithNoBlockUI(response),
                (error: any) => this.handleResponseError(error)).catch(err => this.handleResponseError(err));
    }
    sampleQueryMgmtPostWithXhr(url, data: FormData): Observable<any> {
        
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            let headers = new Headers();
            this.createAuthorizationHeader(headers);
            xhr.open('POST', this.sampleQueryMgmtBaseUrl+url, true);
            xhr.setRequestHeader('Authorization', this.sessionService.getItem('id_token'))
            xhr.send(data);
        });
    }
}

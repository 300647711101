import {Action} from '@ngrx/store'
import {ProductOrderFilter} from '../ProductOrder/Model/productorderfilter.model'

export const update_StudySite = 'Update_StudySite';
export const update_StudySite_Success = 'Update_StudySite_Success';
export const Load_Site = 'Load_Site';
export const Clear_SiteState ='Clear_SiteState'

//update study site details to store
export class UpdateStudySiteSuccesss implements Action {
    readonly type = update_StudySite_Success;

    constructor(public payload: ProductOrderFilter) {

    }

}


//update study site details to session
export class UpdateStudySite implements Action {
    readonly type = update_StudySite;

    constructor(public payload: ProductOrderFilter) {

    }

}

//load study site details to store on page refresh
export class LoadSite implements Action {
    readonly type = Load_Site;

    constructor() {

    }

}

export class ClearSiteState implements Action {
    readonly type = Clear_SiteState;

    constructor() {

    }

}

export type Actions = UpdateStudySiteSuccesss |
    UpdateStudySite |
    LoadSite
    |ClearSiteState;
import {Pipe} from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe {
  transform(fromUIValue: string) : string {
    var temp="";
    if(fromUIValue){
        var strArray = fromUIValue.split(",");
        if(strArray.length > 0){
            if(strArray.length > 1){
                temp=strArray[0]+'...';
            }else{
                temp=strArray[0];
            }
        }else{
            temp=fromUIValue;
        }
    }else{
         temp = fromUIValue;
    }
    
    return temp;
  }
}
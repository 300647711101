import { environment } from '../../environments/environment';

import {Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromCollection from './ecommportal.state'
import * as reducerCollection from './reducers/collection.reducer'
import * as reducersitestate from './reducers/updateStudySite.reducer'
import * as orderstate from './reducers/order.reducer'



/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
 site: fromCollection.SiteState;
 
  checkout: fromCollection.CartState;

  order : fromCollection.OrderConfirmState
 
 }

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers: ActionReducerMap<AppState>  = {
 
  site : reducersitestate.reducer,
  checkout: reducerCollection.reducer,
  order : orderstate.reducer

};


export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState, Action> {
  return function(state: AppState, action: Action): AppState {
 

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];

import {ActionReducer,Action} from '@ngrx/store';
import { fakeAsync } from '@angular/core/testing';
//import {IState, initialState} from ''

export interface IState{
    counter:number;
    loggedIn:boolean;
    id_Token:any;
    isExternal:boolean;
    isSuperUser:boolean;
    lastLoggedIn:any;
    loggedInUserId:any;
    roleId:any;
    userPermissions:any[];
    userSponsorId:any;
}

export const initialState: IState = {
    counter: 0,
    loggedIn : false,
    id_Token:null,
    isExternal:false,
    isSuperUser:false,
    lastLoggedIn:null,
    loggedInUserId:null,
    roleId:null,
    userPermissions:[],
    userSponsorId:null

}

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
// This is the action fired when the activity timeout occured.
//  We keep it generic so the reducer can take any number 
//  of actions based on it (including perhaps none)
//
export const ACTIVITY_TIMEOUT_OCCURRED = "ACTIVITY_TIMEOUT_OCCURRED";

function getStateObject(state:IState, counter:number, isLoggedIn:boolean):IState{
    return {
        counter:counter,
        loggedIn:isLoggedIn,
        id_Token:state.id_Token,
        isExternal:state.isExternal,
        isSuperUser:state.isSuperUser,
        lastLoggedIn:state.lastLoggedIn,
        loggedInUserId:state.loggedInUserId,
        roleId:state.roleId,
        userPermissions:state.userPermissions,
        userSponsorId:state.userSponsorId
    }
}
export const authenticationValidationReducer:ActionReducer<IState> = (state:IState,action:Action)=> {
    switch(action.type){
        case INCREMENT:return getStateObject(state,state.counter+1, state.loggedIn)
        case DECREMENT:return getStateObject(state,state.counter-1, state.loggedIn)
        case RESET:
        case USER_LOGGED_OUT: return getStateObject(initialState,0, false)
        case USER_LOGGED_IN:return getStateObject(state,1, true)
        default:return state;
    }
}
 
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function onlySpacesNotAllowedValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const name = control.value;
    var nameRe = new RegExp('.*[^ ].*');
    if(name ==''||name == null){
      return null;
    }
    const no = nameRe.test(name);
    return no ? null: {'onlyspacesnotallowed': {name}};
  };
}

@Directive({
  selector: '[onlyspacesnotallowed]',
  providers: [{provide: NG_VALIDATORS, useExisting: OnlySpacesNotAllowedDirective, multi: true}]
})
export class OnlySpacesNotAllowedDirective implements Validator{
  
  private valFn = Validators.nullValidator;
constructor(){
this.valFn = onlySpacesNotAllowedValidator();
}
  
  validate(control: AbstractControl): {[key: string]: any} {
    return this.valFn(control);
  }
}
import { Component, Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CanDeactivate }        from '@angular/router';
import {ConfirmationService} from 'primeng/primeng';

@Component({
  selector: 'fetch-basecomponent',
  template: `
   <p-confirmDialog header="Confirmation Message"
                 icon="fa fa-trash" width="400">sdsds
  </p-confirmDialog>
  `,
})
export class FetchBaseComponent{
  public applicationFormObject:NgForm;
  public isMyPageDirty:boolean=false;
  dateFilterFormat = 'dd/M/yy';
  defaultRowsInGrid = 10;
  dateTimeFormat = 'dd/MMM/yyyy hh:mm a';
  dateFormat = 'dd/MMM/yyyy';

                  
  
setPageDirty(isMyPageDirty){
        this.isMyPageDirty=isMyPageDirty; 
    }
    setFormObject(formData){
        this.applicationFormObject = formData;
    }
    resetFormChanges(){
        this.isMyPageDirty = false;
        if(this.applicationFormObject && this.applicationFormObject !=null)
        {
            this.applicationFormObject=null;
        }
    }
}
@Injectable()
export class UnSavedChangesConfirmDialogGuard implements CanDeactivate<FetchBaseComponent> {
    constructor(private confirmationService:ConfirmationService) {
    }
     canDeactivate(target: any):Promise<boolean>|boolean{
        if(target.isMyPageDirty=== true ||(target.applicationFormObject && target.applicationFormObject.dirty === true )){
         return new Promise((observer) => {
            this.confirmationService.confirm({
               message:"All the information would be lost, are you sure you want to move out of the screen?",
                accept: () => {
                  observer(true);
                },
                reject: () => {
                    observer(false);
                }
            });
        });
        //return window.confirm("All the information would be lost, are you sure you want to move out of the screen?");
     }else{
         return true;
     }
     }
    }


import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { getCartItems,getstudysitedetails} from '../../reducers/selector';
import {AppState} from '../../ecommportal.reducer';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as collection from '../../actions/collection.actions';
import {ClearSiteState} from '../../actions/updateStudySite.actions';
import { SessionService } from '../../../shared/services/session.service';
import {LoadSite} from '../../actions/updateStudySite.actions'
import {clearOrderState} from '../../actions/order.actions'
import { ConfirmationService } from 'primeng/components/common/api';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit{

    studyNumber : any;
    siteCode: string;
    pagevariable :any
    totalItems : number;
   
    constructor(
        private store: Store < AppState > ,
        private router: Router,
        private sessionService : SessionService,
        private confirmationService: ConfirmationService
      ) {
    
    }
    ngOnInit(): void { 

        this.store.dispatch(new LoadSite())
        this.store.select(getstudysitedetails).subscribe(res => {
            this.studyNumber = res.studyNumber;
            this.siteCode = res.siteCode;
           
          
        });
        this.getCartItems();
       
    }

    routeToProduct() {
        this.pagevariable = "1";

          if(this.totalItems >0){
          this.confirmationService.confirm({
            message: "There are some items in your Cart!! Are you sure you want to switch the Study/Site?",
            icon: 'fa fa-check',
            accept: () => {
                this.processNavigation();
        
            }
        });
    }
          else{
            this.processNavigation();
          }
     


        
        
    }

    processNavigation()
    {     this.clearItemsfromCart(); 

        this.store.dispatch(new clearOrderState());
      
        this.router.navigate(["/home/Ecom/productorder/" + this.pagevariable]);

    }

    routeToCart() {
       
        this.router.navigate(["/home/Ecom/Cart"]);
    }

    clearItemsfromCart() {
        var keys;
        this.store.select(getCartItems).subscribe(res => {
            keys = res.map(i => i.key);
        });
  
        this.sessionService.setItem('studySiteKey', "0");
        this.store.dispatch(new collection.RemoveDbItems(keys));
        this.store.dispatch(new ClearSiteState());
    }


    getCartItems() {
        this.store.select(getCartItems).subscribe(res => {
           
            this.totalItems = res.length;         
        }); 
    }
}

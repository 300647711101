﻿import { Directive, ElementRef, Input } from '@angular/core';
import { SessionService } from '../services/session.service';

@Directive({
    selector: '[disableElement]'
})

export class DisableElementDirective {
    @Input('disableElement') featureId: string;
    userPermissions: any;
    constructor(private _el: ElementRef,
        private sessionService: SessionService) {
        this.userPermissions = JSON.parse(this.sessionService.getItem('userPermissions'));
    }
    ngOnInit() {
        this.setDisableElement();
    }

    setDisableElement() {
        const features = this.userPermissions.filter(x => x.FeatureId.toUpperCase() === this.featureId);
        if (features.length > 0) {
            const isReadAccess = features[0].ReadAccess && features[0].WriteAccess == false;
            if (isReadAccess) {
                this._el.nativeElement.disabled = true;
                if (this._el.nativeElement.style) {
                    this._el.nativeElement.style.pointerEvents = 'none';
                    this._el.nativeElement.style.opacity = 1;
                } 
            }
        }
    }
}

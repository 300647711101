import { DBSchema } from '@ngrx/db';
/*initialize store in indexeddb */
export const schema: DBSchema = {
  version: 1,
  name: 'Product_dbv1',
  stores: {
    cartitems: {
      
      primaryKey: 'key'
    },
    orders:{

      primaryKey: 'orderKey'
    }

  }
};

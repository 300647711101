﻿import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { HttpClient } from '../HttpClient';
import { SessionService } from './session.service';

@Injectable()
export class RolesPermissionsService {
    httpClient: HttpClient;
    featurePermissions: any;
    constructor(httpClient: HttpClient, private sessionService: SessionService) {
        this.httpClient = httpClient;
    }
    
    public getFeaturePermission(userId: string): any  {
        return this.httpClient.get('api/UserQuery/getFeaturePermissions/' + userId).map(responsedata => {
            var result = responsedata.json();
            this.featurePermissions = result;
            return result;
        }); 
    }

    public checkUserHasReadOnlyPermission(featureId: string) {
        const userPermissions = JSON.parse(this.sessionService.getItem('userPermissions'));
        const features = userPermissions.filter(x => x.FeatureId.toUpperCase() === featureId);
        if (features.length > 0) {
            const isReadAccess = features[0].ReadAccess && features[0].WriteAccess == false;
            if (isReadAccess) {
                return true;
            }
        }
        return false;
    }
    

    //http error handling
    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

}

